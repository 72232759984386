import React, { useState, useEffect, createContext, useContext,useRef  } from "react";
import { format } from "date-fns";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import '../../../css/PurchaseOrderReport.css';
import axios from "axios";
import { API } from "../../../services/api";
import { AlertContext } from "../../../App";
import UpdateStoreProductOrder from "./update/updateStoreProductOrder";
import { useReactToPrint } from 'react-to-print';
import Alert from "../../../components/alert";
import logo from '../../../img/logo/ManeeCafeLogo.jpg';

axios.defaults.baseURL = API;

const PendingBillContent = createContext();


class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      let SubTotal = 0;
      let Quantity = 0;
      let i =0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date()));
      return (
          <div className="PurchaseOrderReportContent">
              <div className="ReportHeader">
                <div className="ReportHeaderBody">
                      <img className="profile-img" src={logo} alt="logo" />
                      <div className="ReportTitle">
                          <div className="titleHeader1">
                            <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                          </div>
                          <div className="titleHeader2">
                              <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                          </div>
                          <div className="titleHeader2">
                              <span>**********</span>
                          </div>
                          <div className="titleHeader3">
                              <span>ລາຍງານ ບິນຮ້ານເບີກເຄື່ອງສາງ</span>
                          </div>
                      </div>
                </div>
                <div className="ReportDate">
                      <span>ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="ReportBill">
                      <span>ສາຂາ: {billDetails.LocationName}</span>
                  </div>
                  <div className="ReportBill">
                      <span>ເລກບິນ: {billDetails.BillNO}</span>
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                        <span className="CountNumber">NO.</span>
                        <span className="Barcode">Barcode</span>
                        <span className="ProductType">ປະເພດສິນຄ້າ</span>
                        <span className="Category">ໝວດສິນຄ້າ</span>
                        <span className="Brand">ຫຍີ່ຫໍ້</span>
                        <span className="SizeType">Size Type</span>
                        <span className="Size">Size/ຈຳນວນ</span>
                        <span className="Retail_Price">ລາຄາ/ລວມ</span>
                  </div>
                  <div className="HeadLine">
                      <div className="footer-line"/>
                    </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            SubTotal =  (Number(x.Quantity) * Number(x.BuyPrice)) +  Number(SubTotal);
                            Quantity =  Number(x.Quantity) +  Number(Quantity);
                      if(y < 22){
                        return (
                          <div className="Items">
                              <div className="Detail">
                                  <div className="mds">
                                      <span className="NO">{(y + 1) }</span>
                                      <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                      <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                      <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                      <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                      <span className="SizeType">{x.SizeType.length > 10 ? x.SizeType.substring(0, 10) + '...' : x.SizeType}</span>
                                      <span className="Size">{x.Size}</span>
                                      <span className="Retail_Price">{currencyFormat(x.BuyPrice)}</span>
                                  </div>
                              </div>
                              <div className="ProductName">
                                      <span className="LaoName">{x.LaoName}</span>
                                      <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                      <span className="sTotal">{currencyFormat(Number(x.Quantity) * Number(x.BuyPrice))}</span>
                                  </div>
                                <div className="p-line"/>
                          </div>
                        )
                      } else if (i === 0) {
                        i++;
                        return (
                          <div className="Items">
                              <div className="Detail">
                              <div className="newline"></div>
                                    <div className="mds">
                                      <span className="NO">{(y + 1) }</span>
                                      <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                      <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                      <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                      <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                      <span className="SizeType">{x.SizeType}</span>
                                      <span className="Size">{x.Size}</span>
                                      <span className="Retail_Price">{currencyFormat(x.BuyPrice)}</span>
                                  </div>
                              </div>
                              <div className="ProductName">
                                      <span className="LaoName">{x.LaoName}</span>
                                      <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                      <span className="sTotal">{currencyFormat(Number(x.Quantity) * Number(x.BuyPrice))}</span>
                              </div>
                              <div className="p-line"/>
                          </div>
                        )
                      } else {
                          i++;
                          if(i=== 29)
                              i=0;
                          return (
                            <div className="Items">
                                <div className="Detail">
                                  <div className="mds">
                                        <span className="NO">{(y + 1) }</span>
                                        <span className="Barcode">{x.Barcode.length > 20 ? x.Barcode.substring(0, 20) + '...' : x.Barcode}</span>
                                        <span className="ProductType">{x.ProductType.length > 25 ? x.ProductType.substring(0, 25) + '...' : x.ProductType}</span>
                                        <span className="Category">{x.Category.length > 25 ? x.Category.substring(0, 25) + '...' : x.Category}</span>
                                        <span className="Brand">{x.Brand.length > 12 ? x.Brand.substring(0, 12) + '...' : x.Brand}</span>
                                        <span className="SizeType">{x.SizeType}</span>
                                        <span className="Size">{x.Size}</span>
                                        <span className="Retail_Price">{currencyFormat(x.BuyPrice)}</span>
                                    </div>
                                </div>
                                <div className="ProductName">
                                      <span className="LaoName">{x.LaoName}</span>
                                      <span className="Quantity">{currencyFormat(x.Quantity)}</span>
                                      <span className="sTotal">{currencyFormat(Number(x.Quantity) * Number(x.BuyPrice))}</span>
                                </div>
                                <div className="p-line"/>
                            </div>
                          )
                      }
                      })
                    }
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="sQuantity">{currencyFormat(Quantity)}</span>
                      <span className="SubTotal">{currencyFormat(SubTotal)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ຮັບເຄື່ອງ</span>
                      <span className="StockAdmin">ຂົນສົ່ງ</span>
                      <span className="finance">ຜູ້ດູແລສາງ</span>
                  </div>
              </div>
          </div>
      );
  }
}

function StoreOrderPendingBill() {
  function getDate(gdate) {
    var date = new Date(gdate);
    return format(date, "dd-MM-yyyy HH:mm");
  }
  const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);
  const componentRef = useRef(); 
  const [SupplierList, setSupplierList] = useState([]);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState([]);
  const [PurchaseOrderDetail, setPurchaseOrderDetail] = useState([]);
  const [showData, setShowData] = useState([]);
  const [BillNO, setBillNO] = useState(null);
  const [LocationName, setLocationName] = useState("");



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function onClickPrint(StoreOrderID, LocationName) {
    await axios.get(`/getStorePurchaseOrderProduct/${StoreOrderID}`).then((response) => {
      let Data = response?.data;
      if(Data){
        setLocationName(LocationName);
        setShowData(Data);
        setBillNO(StoreOrderID);
      } else {
        setLocationName("");
        setShowData([]);
        setBillNO("");
      }
      if(Data)
        handlePrint();
    }).catch((error) => {
      console.log(error);
    });
  }

  async function getPendingBill() {
    var StatusID = 2;
    await axios.get(`/getStorePurchaseOrderBillForStock/${StatusID}`).then((response) => {
        let Data = response?.data;
        setDataList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function CancelPurchaseOrder(StoreOrderID) {
      let token = localStorage.getItem("token");
      var data = {
        StatusID: 3,
      }
      await axios.put(`/updateStorePurchaseOrderStatus/${StoreOrderID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        getPendingBill();
      }).catch((err) => {
          console.log(err);
      });
  };

  async function OrderProduct(StoreOrderID) {
    let token = localStorage.getItem("token");
    var data = {
      StatusID: 5,
    }
    await axios.put(`/updateStorePurchaseOrderStatus/${StoreOrderID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      getStoreOrderProductForUpdate(StoreOrderID);
      getPendingBill();
    }).catch((err) => {
        console.log(err);
    });
  };

  async function getStoreOrderProductForUpdate(StoreOrderID) {
    await axios
      .get(`/getStorePurchaseOrderProduct/${StoreOrderID}`)
      .then((response) => {
        let storeOrderData = response?.data;
        getInventory(storeOrderData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getInventory(storeOrderData) {
    if(storeOrderData.length > 0) {
        for (var i=0; i < storeOrderData.length; i++) {
            await axios.get(`/getInventory/${storeOrderData[i].ProductID}`).then((response) => {
                let Data = response?.data;
                if(Data.length > 0){
                    var qty = Number(Data[0].Quantity) - Number(storeOrderData[i].Quantity);
                    UpdateInventory(Data[0].InventoryID, qty);
                }
            })
              .catch((error) => {
                console.log(error);
            });
        } 
    }
  }

  async function UpdateInventory(InventoryID, qty) {
    let token = localStorage.getItem("token");
    var data = {
        Quantity: qty
    }
    await axios.put(`/updateInventory/${InventoryID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        // props.onClick(false);
    }).catch((err) => {
        console.log(err);
    });
}
  
  async function openProductDetail(StoreOrderID) {
    await axios
      .get(`/getStorePurchaseOrderProduct/${StoreOrderID}`)
      .then((response) => {
        let Data = response?.data;
        setPurchaseOrderDetail(Data);
        handleShow();
      })
      .catch((error) => {
        console.log(error);
      });
  }


  useEffect(() => {

    getPendingBill();

  }, []);

  return (
    <PendingBillContent.Provider value={{dataList, setDataList, showAlert, setShowAlert, errorMessage, setErrorMessage, SupplierList, PaymentTypeList, data, PurchaseOrderDetail, setPurchaseOrderDetail}}>
      <div className="formContent">
      <Alert/> 
        <UpdateStoreProductOrder show={show} onClick={handleClose}/>
        <div className="Header">
          <div className="title">
              <span>ເບີກເຄື່ອງໄປຮ້ານ</span>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ສາຂາ</th>
                <th>ມື້ສ້າງ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                dataList && dataList.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.StoreOrderID}</td>
                        <td >{x.LocationName}</td>
                        <td >{getDate(x.CreatedDate)}</td>
                        <td >
                          <button className="btn btn-secondary" type="button" onClick={() => onClickPrint(x.StoreOrderID, x.LocationName)}>ພິມລາຍງານ</button>
                            &nbsp;&nbsp;&nbsp;
                          <button className="btn btn-warning" type="button" onClick={() => openProductDetail(x.StoreOrderID)}>ລາຍລະອຽດ</button>
                            &nbsp;&nbsp;&nbsp;
                          <button className="btn btn-primary" type="button" onClick={() => OrderProduct(x.StoreOrderID)}>ສັ່ງເບີກ</button>
                            &nbsp;&nbsp;&nbsp;
                          <button className="btn btn-danger" type="button" onClick={() => CancelPurchaseOrder(x.StoreOrderID)}>ຍົກເລີກບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"BillNO": BillNO,"LocationName": LocationName}} 
            />
        </div>
      </div>
    </PendingBillContent.Provider>
  );
};

export {PendingBillContent};
export default StoreOrderPendingBill;
