import React, { useState, useContext, useRef, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../services/api";
import '../../../../css/posOrder.css';
import '../../../../css/BillForKitchen.css';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { TransactionContent } from "../trasactionService";
import { useReactToPrint } from 'react-to-print';
import { UserContext } from "../../../../App";

axios.defaults.baseURL = API;

class PrintBill extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "" && num >0) {
                return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const OrderList = this.props.TempOrderList;
        const billDetails = this.props.BillDetails;
        const today = Date.now();
        var pkID = 0;
        const customerName = this.props.custmer;
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="KitchenBillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Spa</span>
                    </div>
                    <div className="TableNo">
                        <span>ຫ້ອງ # {billDetails.RoomName}</span>
                    </div>
                    <div className="Cashier">
                        <span>ພະນັກງານ: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>ວັນທີ: {dateNow}</span>
                    </div>
                    <div className="BillTitle">
                        <span>ບິນລາຍການນວດ</span>
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                    <span className="CountNumber">ລ/ດ.</span>
                        <span className="ProductName">ລາຄາ</span>
                        <span className="Qty">ຊົວໂມງ</span>
                        <span className="Price">ລວມ</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"/>
                        <div className="footer-line"/>
                    </div>
                    <div className="BodyDetails">
                        {
                            OrderList && OrderList.map((x, y) => {
                                return (
                                    <div className="Items">
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="Price">{currencyFormat(x.ServicePrice)}</span>
                                                <span className="Qty">{parseFloat(x.Hour)}</span>
                                                <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                            </div>
                                        </div>
                                        <div className="Name">
                                            <span className="ProductName">{x.ServiceNameLao + '   (' + x.EmployeeName + ')'}</span>  
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        );
    }
}

class PrintReciveBill extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "" && num >0) {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const OrderList = this.props.TempOrderList;
        const billDetails = this.props.BillDetails;
        const today = Date.now();
        var pkID = 0;
        const customerName = this.props.custmer;
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="KitchenBillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Spa</span>
                    </div>
                    <div className="TableNo">
                        <span>Room # {billDetails.RoomName}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="BillTitle">
                        <span>ບິນຄ່າບໍລິການນວດ</span>
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                    <span className="CountNumber">NO</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Hour</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"/>
                        <div className="footer-line"/>
                    </div>
                    <div className="BodyDetails">
                        {
                            OrderList && OrderList.map((x, y) => {
                                return (
                                    <div className="Items">
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="Price">{currencyFormat(x.ServicePrice)}</span>
                                                <span className="Qty">{parseFloat(x.Hour)}</span>
                                                <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                            </div>
                                        </div>
                                        <div className="Name">
                                            <span className="ProductName">{x.ServiceNameLao + '   (' + x.EmployeeName + ')'}</span>  
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="BillFooter">
                    <div className="footer-line"></div>
                    <div className="subtotal">
                        <span>SubTotal:</span>
                        <strong>{currencyFormat(billDetails.SubTotal)}</strong>
                    </div>
                    <div className="subtotal">
                    </div>
                    <div className="footer-line"></div>
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>

                </div>
            </div>
        );
    }
}


function TransactionOrder() {

    const {userID, UserName, LocationID, LocationName} = useContext(UserContext);
    const {RoomName, RoomID, setShowPayment, showOrder, TempOrder, setTempOrder, setShowOrder, serverName, 
           subTotal, setSubTotal, setNetTotal, setTax, show, setShow, setShowAlert, setErrorMessage,RoomList, setRoomList,
           ServiceID, setServiceID, ServiceType, setServiceType, ServicePrice, setServicePrice, ServiceNameLao, 
           setServiceNameLao, PackageID, setPackageID,PackageName, setPackageName,PackagePrice, setPackagePrice,
           pHour, setpHour, ServiceNameEng, setServiceNameEng, Commission, setCommission} = useContext(TransactionContent);

    const componentRefBill = useRef();
    const componentRefReciveBill = useRef();
    const [menuList, setMenuList] = useState([]);
    const [ServiceTypeList, setServiceTypeList] = useState([]);
    const [ServiceList, setServiceList] = useState([]);

    function currencyFormat(num) {
        if (num !== "" && num > 0) {
            return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    async function handleClickGetServiceCategory(ServiceTypeID, ServiceType) {
        if(ServiceType == "Package" || ServiceType == "PACKAGE"){
           await axios.get("/getAllPackage").then((response) => {
            let temOrder = response?.data;
            if(temOrder.length > 0){
                setServiceTypeList(temOrder);
                setServiceList([]);
            } else {
                setServiceTypeList([]);
                setServiceList([]);
            }
            }).catch((err) => {
                setServiceTypeList([]);
                setServiceList([]);
                console.log(err);
            });
        } else {
            let token = localStorage.getItem("token");
            await axios.get(`/getServiceCategoryByServiceTypeID/${ServiceTypeID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let temOrder = response?.data;
            if(temOrder.length > 0){
                setServiceTypeList(temOrder);
                setServiceList([]);
            } else {
                setServiceTypeList([]);
                setServiceList([]);
            }
            }).catch((err) => {
                setServiceTypeList([]);
                setServiceList([]);
                console.log(err);
            });
        }
    };

    async function handleClickserviceCategoryToGetServiceList (ServiceCategoryID, PackageID, ServiceCategory) {
        if(ServiceCategoryID || PackageID){
            if(PackageID > 0){
                let token = localStorage.getItem("token");
                await axios.put(`/getPackageDetailByPackageID/${PackageID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                  let temOrder = response?.data;
                  if(temOrder.length > 0){
                      setServiceList(temOrder);
                  } else {
                      setServiceList([]);
                  }
                }).catch((err) => {
                  setServiceList([]);
                  console.log(err);
                });

            } else {
                let token = localStorage.getItem("token");
                await axios.put(`/getServiceByCatetoryID/${ServiceCategoryID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                  let temOrder = response?.data;
                  if(temOrder.length > 0){
                      setServiceList(temOrder);
                  } else {
                      setServiceList([]);
                  }
                }).catch((err) => {
                  setServiceList([]);
                  console.log(err);
                });
            }
        } else {
            setServiceList([]);
        }
      }

    async function addOrder(ServiceID, ServiceType, ServicePrice, ServiceNameLao, ServiceNameEng, Hours, PackageID, PackageName, PackagePrice, Commission) {
        setShow(true);
        setServiceID(ServiceID);
        setServiceType(ServiceType);
        setServicePrice(ServicePrice);
        setServiceNameLao(ServiceNameLao);
        setServiceNameEng(ServiceNameEng);
        setpHour(Hours);
        setPackageID(PackageID);
        setPackageName(PackageName);
        setPackagePrice(PackagePrice);
        setCommission(Commission);
        getSubtotal();
        console.log(Commission)
    }

    async function minus(ServiceID, gtHour) {
        if(TempOrder.length > 0){
            
            for (var i=0; i < TempOrder.length; i++) {
                
                if (TempOrder[i].ServiceID == ServiceID) {
                    var test = parseFloat(TempOrder[i].Hour) + parseFloat(gtHour);
                    console.log(test)
                    if(parseFloat(TempOrder[i].Hour) + parseFloat(gtHour) > 0)
                    {
                        TempOrder[i].Hour = parseFloat(TempOrder[i].Hour) + parseFloat(gtHour);
                        TempOrder[i].SubTotal = parseFloat(TempOrder[i].Hour) * Number(TempOrder[i].ServicePrice);
                    } else {
                        TempOrder.splice(i, 1);
                    }
                    break;
                }
            }  
            localStorage.setItem(RoomID, JSON.stringify(TempOrder));
            getSubtotal();
        }

    }

    async function UpdateTempOrderFoodPrintStatus() {
        for (var i=0; i < TempOrder.length; i++) {
            if(TempOrder[i].PrintStatus === "N")
                TempOrder[i].PrintStatus = "Y";
            else 
                TempOrder[i].QuantityPrint = 0;
        }  
        localStorage.setItem(RoomID, JSON.stringify(TempOrder));
        getSubtotal();
    };

    const handlePrintBill = () => {
        UpdateRoom();
        printBill();
    }

    const handlePrintReciveBill = () => {
        printReciveBill();
    }

    const printBill = useReactToPrint({
        content: () => componentRefBill.current,
    });

    const printReciveBill = useReactToPrint({
        content: () => componentRefReciveBill.current,
    });


    async function UpdateRoom() {
        let token = localStorage.getItem("token");
        var data = {
            StatusID: 2
        }
        await axios.put(`/UpdateRoomForSelectRoom/${RoomID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllRoom();
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllRoom() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAllRoomForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setRoomList(temOrder);
          } else {
            setRoomList([]);
          }
        }).catch((err) => {
            setRoomList([]);
            console.log(err);
        });
    }

   

    const getSubtotal = () => {
        let userData = localStorage.getItem(RoomID);
        if (userData) {
          let userRole = JSON.parse(userData);
          setTempOrder(userRole);
          if(userRole.length > 0){
                var gSubTotal = 0;
                for (var i=0; i < userRole.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(userRole[i].SubTotal);
                }  
                setSubTotal(gSubTotal);
                setNetTotal(gSubTotal);
                var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /110)).toFixed(2);
                setTax(tax);
            } else{
                setSubTotal(0);
                setNetTotal(0);
                setTax(0);
            }
        } else{
            setTempOrder([]);
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
        }
    }

    async function createTemOrderWhenclickclose () {
        setShowOrder(false)
    }

    const handleShowPayment = () => {
       if(TempOrder.length > 0){
        setShowPayment(true);
       } else{
            setErrorMessage("ກະລຸນາເລືອກລາຍການນວດກ່ອນ");
            setShowAlert(true);
       }
    }

    async function getMenu() {
        axios
        .get("/getAllServiceType")
        .then((response) => {
            let Data = response?.data;
            setMenuList(Data);
        })
        .catch((error) => {
            console.log(error);
        });
      }

    useEffect(() => {
        getMenu();
        getSubtotal();
      }, []);

    return (
        <div style={{ display: showOrder ? 'flex' : 'none' }} className="formPos">
            <div className="OrderContent">
                <div className="LeftContent">
                    <div className="imgLogo">
                        <img className="logo" src={logo} />
                    </div>
                    <div className="SubMenu">
                        {
                            ServiceTypeList && ServiceTypeList.map((x, y) => {
                                return (
                                    <div key={y} className="SubMenuContent" onClick={() => handleClickserviceCategoryToGetServiceList(x.ServiceCategoryID, x.PackageID, x.ServiceCategory)}>
                                        <img />
                                        <p className="name">{x.ServiceCategory || x.PackageName}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="CenterContent">
                    <div className="menuContent">
                        {
                            menuList && menuList.map((x, y) => {
                                return (
                                    <div key={y} className="FoodMenu" onClick={() => handleClickGetServiceCategory(x.ServiceTypeID, x.ServiceType)}>
                                        <div className="FoodMenuContent" tabIndex={y}  >
                                            <div className="item-header">
                                                <img />
                                            </div>
                                            <div className="item-body">
                                                <p className="name">{x.ServiceType}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="underLine">
                        <hr />
                        <span>{} Menu</span>
                    </div>
                    <div className="MenuList">
                    {
                            ServiceList && ServiceList.map((x, y) => {
                                return (
                                    <div key={y} className="item" onClick={() => addOrder(x.ServiceID, x.ServiceType, x.ServicePrice, x.ServiceNameLao, x.ServiceNameEng, x.Hours, x.PackageID, x.PackageName, x.PackagePrice, x.Commission)}>
                                        <div className="item-header">
                                            <img src={x.ImgUrl} className="profile-img" />
                                        </div>
                                        <div className="item-body">
                                            <div className="name">
                                                <p>{x.ServiceNameLao}</p>
                                            </div>
                                            <p className="price">{currencyFormat(x.ServicePrice)}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="RightContent">
                    <div className="billNo">
                        <div className="ordertext"><p>Order List</p></div>
                        <div className="tableNo"><p>ຫ້ອງ # {RoomName} </p></div>
                        <div className="btnclose" onClick={e => createTemOrderWhenclickclose()} >X</div>
                    </div>
                    <div className="OrderHead">
                        <div className="ProductName"><p>Price</p></div>
                        <div className="Hour">Hour</div>
                        <div className="Price">Total</div>
                    </div>
                    <div className="posRightSidenavContent">
                        {
                            TempOrder && TempOrder.map((x, y) => {
                                return (
                                    <div key={y} className="orderContent">
                                        <div className="orderFooter">
                                            <div className="price">
                                                <p>{currencyFormat(x.ServicePrice)}</p>
                                            </div>
                                            <div className="plus" onClick={() => addOrder(x.ServiceID, x.ServiceType, x.ServicePrice, x.ServiceNameLao, x.ServiceNameEng, 0.5)}>
                                                +
                                            </div>
                                            <div className="Quantity">
                                                <p>{currencyFormat(x.Hour)}</p>
                                            </div>
                                            <div className="minus" onClick={() => minus(x.ServiceID, -0.5)}>
                                                -
                                            </div>
                                            <div className="Subtotal">
                                                <p>{currencyFormat(x.Hour * x.ServicePrice)}</p>
                                            </div>
                                        </div>
                                        <div className="orderBody">
                                            <div className="detail">
                                                <div className="productName">
                                                    <span>{y+1}. {x.ServiceNameLao}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="posRightSidenavBottom">
                        <div className="footer-subtotal">
                            <div className="footer-line"></div>
                            <div className="nettotal">
                                <span>Sutotal:</span>
                                <strong>{currencyFormat(subTotal)}</strong>
                            </div>
                        </div>
                        <div className="paymentMethod">
                            <div className="btnpayment" onClick={(e) => handleShowPayment()}>
                                <span>Payment</span>
                            </div>
                            <div className="btnPrintFood" onClick={(e) =>handlePrintReciveBill()} >
                                <span>Print ReciveBill</span>
                            </div>
                            <div className="btnPrintFood" onClick={(e) =>handlePrintBill()} >
                                <span>Print Bill</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'none'}}>
                <PrintBill ref={componentRefBill}
                    TempOrderList = {TempOrder}
                    BillDetails={{"RoomName": RoomName, "cashier": UserName}}
                />
                  <PrintReciveBill ref={componentRefReciveBill}
                    TempOrderList = {TempOrder}
                    BillDetails={{"RoomName": RoomName, "cashier": UserName, "SubTotal": subTotal}}
                />
            </div>

        </div>
    );
};

export default TransactionOrder;
