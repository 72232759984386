function TopUpMemberSchema(values) {
    let errors = {};

    if (!values.MemberNO)
        errors.MemberNO = 'MemberNO is required';

    if (!values.TopUpAmount)
        errors.TopUpAmount = 'TopUpAmount is required';

    if (!values.PaidAmount)
        errors.PaidAmount = 'PaidAmount is required';

    if (values.PaidAmount <= 0)
        errors.PaidAmount = 'PaidAmout not enough';

    if (!values.PaymentTypeID)
        errors.PaymentTypeID = 'PaymentTypeID is required';

        if (!values.EmployeeID)
        errors.EmployeeID = 'EmployeeID is required';

    return errors;
};

export default TopUpMemberSchema;
