import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/RegisterTable.css';
import axios from "axios";
import { API } from "../../../services/api";
import Alert from "../../../components/alert";
import TransactionOrder from "./create/transactionOrder";
import SelectEmployee from "./selectEmployee";
import Payment from "./create/payment";

import { AlertContext, UserContext } from "../../../App";

axios.defaults.baseURL = API;

const TransactionContent = createContext();


function TrasactionService() {

const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);
const {userID, LocationID} = useContext(UserContext);

  const [RoomName, setRoomName] = useState([]);
  const [RoomID, setRoomID] = useState([]);
  const [RoomList, setRoomList] = useState([]);
  const [subTotal, setSubTotal] = useState([]);
  const [NetTotal, setNetTotal] = useState([]);
  const [Tax, setTax] = useState(0);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [DiscountList, setDiscountList] = useState([]);
  const [TempOrder, setTempOrder] = useState([]);
  const [Commission, setCommission] = useState(0);
  

  const [ServiceID, setServiceID] = useState("");
  const [ServiceType, setServiceType] = useState("");
  const [ServicePrice, setServicePrice] = useState("");
  const [ServiceNameLao, setServiceNameLao] = useState("");
  const [ServiceNameEng, setServiceNameEng] = useState("");
  const [pHour, setpHour] = useState("");
  const [PackageID, setPackageID] = useState("");
  const [PackageName, setPackageName] = useState("");
  const [PackagePrice, setPackagePrice] = useState("");
  
  const [show, setShow] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showPayment, setShowPayment] = useState(false);


    async function handelclickRegisterTable(RoomID, RoomName, StatusID) {
        if(StatusID == 1){
            setRoomID(RoomID);
            setRoomName(RoomName);
            getTemOrder(RoomID);
            let token = localStorage.getItem("token");
            var data = {
                RoomName: RoomName, 
                StatusID: 1,
            }
            await axios.put(`/updateRoom/${RoomID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                getAllRoom();
                setShowOrder(true);
                setShow(false);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setRoomID(RoomID);
            setRoomName(RoomName);
            getTemOrder(RoomID);
            setShowOrder(true);
        }
    };



    async function getTemOrder (RoomID) {
        let token = localStorage.getItem("token");
        setTempOrder([]);
        let userData = localStorage.getItem(RoomID);
        if (userData) {
            let TemData = JSON.parse(userData);
            setTempOrder(TemData);
            if(TemData.length > 0){
                var gSubTotal = 0;
                for (var i=0; i < TemData.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(TemData[i].SubTotal);
                }  
                setSubTotal(gSubTotal);
                setNetTotal(gSubTotal);
                var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /110)).toFixed(2);
                setTax(tax);
            } else{
                setSubTotal(0);
                setNetTotal(0);
                setTax(0);
            }
        }
    }

    async function getAllRoom() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAllRoomForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setRoomList(temOrder);
          } else {
            setRoomList([]);
          }
        }).catch((err) => {
            setRoomList([]);
            console.log(err);
        });
    }

    async function getAllDiscount() {
        axios
          .get("/getAllDiscount")
          .then((response) => {
            let Data = response?.data;
            setDiscountList(Data);
          })
          .catch((error) => {
            console.log(error);
        });
      }
    
    useEffect(() => {
        getAllRoom();
        let paymentData = localStorage.getItem("paymentTypeData");
            if (paymentData) {
                let payment = JSON.parse(paymentData);
                setPaymentTypeList(payment);
            }
            let currencyData = localStorage.getItem("currencyData");
            if (currencyData) {
                let currency = JSON.parse(currencyData);
                setCurrencyList(currency);
            }
            getAllDiscount();
    }, []);

  return (
    <TransactionContent.Provider value={{RoomName, setRoomName, RoomID, setRoomID,LocationID, RoomList, setRoomList, PaymentTypeList, 
    TempOrder, setTempOrder, CurrencyList, DiscountList, show, setShow, showPayment, setShowPayment, 
    showOrder, setShowOrder, subTotal, setSubTotal, NetTotal, setNetTotal,
    ServiceID, setServiceID, ServiceType, setServiceType, ServicePrice, setServicePrice, ServiceNameLao, setServiceNameLao, 
    ServiceNameEng, PackagePrice, setPackagePrice,Commission, setCommission,
    setServiceNameEng, pHour, setpHour,PackageID, setPackageID, PackageName, setPackageName,
    Tax, setTax, showAlert, setShowAlert, errorMessage, setErrorMessage}}>
      <div className="tableFormContent">
        <Alert/> 
        <SelectEmployee show={show} />
        <TransactionOrder show={showOrder}/>
        <Payment show={showPayment}/>
        <div className="tableContent">
            <div className="tableBody">
                <div className="tableItem">
                    {
                        RoomList && RoomList.map((x, y) => {
                            if(x.StatusID == 1){
                                return (
                                    <div key={x.Tabe} className="item" onClick={() => handelclickRegisterTable(x.RoomID, x.RoomName, x.StatusID)} >
                                        <div className="item-body">
                                            <div className="name">
                                                <p>ເລກຫ້ອງ</p>
                                                <p>{x.RoomName}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (x.StatusID == 2){
                                return (
                                    <div key={y} className="item2" onClick={() => handelclickRegisterTable(x.RoomID, x.RoomName, x.StatusID)}>
                                        <div className="item-body2">
                                            <div className="name2">
                                                <p>ເລກຫ້ອງ</p>
                                                <p>{x.RoomName}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
      </div>
    </TransactionContent.Provider>
  );
};

export {TransactionContent};
export default TrasactionService;
