
function ServiceTypeValidate(values) {

    let errors = {};
    if (!values.ServiceType)
        errors.ServiceType = 'ServiceType is required';
    else if (values.CheckServiceType !== "")
        errors.ServiceType = 'ServiceType Already Exit';

    return errors;
};

export default ServiceTypeValidate;
