import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
axios.defaults.baseURL = API;

function CancelSellGiftCardBill() {

  const {userID, LocationID, UserName, LocationName} = useContext(UserContext);
  const [showData, setShowData] = useState([]);

  async function HandleCancelBill (SellGiftCardID , GiftCardID, Amount) {
    let token = localStorage.getItem("token");
    await axios.put(`/CancelGiftCardBill/${SellGiftCardID}/${userID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      getGiftCardByGiftCardID(GiftCardID,Amount); 
      ClearData();
    }).catch((err) => {
        console.log(err);
    });
  }
  async function getGiftCardByGiftCardID(GiftCardID,Amount) {
    let token = localStorage.getItem("token");
    await axios.get(`/getGiftCardByGiftCardID/${GiftCardID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let memberData = response?.data;
        if(memberData.length > 0){
          UpdateGiftCard(GiftCardID, Amount);
        }
    }).catch((err) => {
        console.log(err);
    });
};

async function UpdateGiftCard(GiftCardID, Amount) {
  let token = localStorage.getItem("token");
  var data = {
      GiftCardID: GiftCardID ,
      Price: Amount,
      StatusID: 1,
  }
  await axios.post("/useGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
  }).catch((err) => {
      console.log(err);
  });
};


  const ClearData = () => {
    setShowData([]);
  }

  async function SearchTransaction(e) {
    const { value } = e.target;
    if(value){
      await axios.get(`/SearchSellGiftCardBill/${value}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setShowData([]);
    }
  };

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ຍົກເລີກບິນຂາຍບັດ Gift Card</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາເລກບິນ" onChange={(e) => SearchTransaction(e)} />
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ລາຄາ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.SellGiftCardID }</td>
                        <td >{x.Amount}</td>
                        <td >{x.PaymentType}</td>
                        <td >
                          <button className="btn btn-danger" value={x.TransactionID} type="button" onClick={() => HandleCancelBill(x.SellGiftCardID , x.GiftCardID, x.Amount)}>ຍົກເລີກບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default CancelSellGiftCardBill;
