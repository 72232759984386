import React, { useContext, useEffect, useState } from "react";
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/service/selectEmployeeValidate';
import '../../../css/selectEmployee.css';
import axios from "axios";
import { API } from "../../../services/api";
import { TransactionContent } from "./trasactionService";
import Employee from "../basicInfo/employee";

axios.defaults.baseURL = API;

function SelectEmployee(props) {

    const {  RoomID, LocationID, TempOrder, setTempOrder, show, setShow, setShowOrder, 
        ServiceID, PackageID, setPackageID,PackageName, subTotal, setSubTotal, setNetTotal, setTax,
         setPackageName, ServiceType, ServicePrice, ServiceNameLao, pHour, setpHour, Commission, setCommission,
        ServiceNameEng, PackagePrice, setPackagePrice} = useContext(TransactionContent);
    const [userList, setUserList] = useState([]);

    function currencyFormat(num) {
        if (num !== "" && num > 0) {
            console.log(num)
            return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    const handleInputHour = event => {
        const { name, value } = event.target;
        if (value) {
            handleChange("Hour", value.split(',').join(''));
            setpHour(value.split(',').join(''));
        }else {
            handleChange("Hour", "");
            setpHour("");
        }

    };

    const handleSelectUserName = (e) => {
        var data = userList.filter(function (item) {
            return item.Name == e.target.value
        })
        if (data.length > 0) {
            handleChange("UserName", data[0].Name);
            handleChange("EmployeeID", data[0].EmployeeID);
            if(PackageID > 0)
                handleChange("Hour", pHour);
            else {
                setpHour("");
                handleChange("Hour", "");
            }
        } else {
            handleChange("UserName", "");
            handleChange("EmployeeID", "");
        }
    };

    async function saveTutorial() {
        var datalist = {
            ServiceID: ServiceID,
            SubTotal: parseFloat(values.Hour) * Number(ServicePrice),
            ServiceNameLao: ServiceNameLao,
            ServiceNameEng: ServiceNameEng,
            ServicePrice: ServicePrice,
            RoomID: RoomID,
            Hour: parseFloat(values.Hour),
            PackageID: PackageID || 0,
            PackageName: PackageName || "",
            PackagePrice: PackagePrice || 0,
            StatusID : 1,
            PrintStatus: "N",
            ServiceType: ServiceType,
            EmployeeID: values.EmployeeID,
            EmployeeName: values.UserName,
            Commission: Commission,
            SubTotalCommission: parseFloat(Commission) * values.Hour,
        }
        if(TempOrder.length > 0){
            var c =0;
            
            for (var i=0; i < TempOrder.length; i++) {
                if(PackageID > 0){ 
                    if (TempOrder[i].ServiceID == ServiceID && TempOrder[i].EmployeeID == values.EmployeeID && TempOrder[i].PackageID == PackageID ) {
                        if(parseFloat(TempOrder[i].Hour) + parseFloat(values.Hour) > 0){
                            TempOrder[i].Hour = parseFloat(TempOrder[i].Hour) + parseFloat(values.Hour);
                            TempOrder[i].SubTotal = parseFloat(TempOrder[i].Hour) * Number(TempOrder[i].ServicePrice);
                            TempOrder[i].SubTotalCommission = parseFloat(TempOrder[i].Hour) * Number(TempOrder[i].Commission);
                        } else {
                            TempOrder.splice(i, 1);
                        }
                        c = 1;
                        break;
                    }
                } else {
                    if (TempOrder[i].ServiceID == ServiceID && TempOrder[i].EmployeeID == values.EmployeeID) {
                        if(Number(TempOrder[i].Hour) + Number(values.Hour) > 0)
                        {
                            TempOrder[i].Hour = Number(TempOrder[i].Hour) + Number(values.Hour);
                            TempOrder[i].SubTotal = Number(TempOrder[i].Hour) * Number(TempOrder[i].ServicePrice);
                            TempOrder[i].SubTotalCommission = Number(TempOrder[i].Hour) * Number(TempOrder[i].Commission);
                        } else {
                            TempOrder.splice(i, 1);
                        }
                        c = 1;
                        break;
                    } 
                }
            }  
            if(c == 0){
                TempOrder.push(datalist);
            }
        } else {
            TempOrder.push(datalist);
        }
        localStorage.setItem(RoomID, JSON.stringify(TempOrder));
        getSubtotal();
        ClearData();
        setShowOrder(true);
        setShow(false);
    };

    const getSubtotal = () => {
        let userData = localStorage.getItem(RoomID);
        if (userData) {
          let userRole = JSON.parse(userData);
          setTempOrder(userRole);
          if(userRole.length > 0){
                var gSubTotal = 0;
                for (var i=0; i < userRole.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(userRole[i].SubTotal);
                }  
                setSubTotal(gSubTotal);
                setNetTotal(gSubTotal);
                var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /107)).toFixed(2);
                setTax(tax);
            } else{
                setSubTotal(0);
                setNetTotal(0);
                setTax(0);
            }
        } else{
            setTempOrder([]);
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
        }
    }


    const ClearData = () => {
        handleChange("EmployeeID", "");
        handleChange("UserName", "");
        handleChange("Hour", "");
        setpHour("");
        setPackageID("");
        setPackageName("");
    }

    async function getUserByLocationID() {
        let token = localStorage.getItem("token");
        await axios.get(`/getUserByLocationID/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setUserList(temOrder);
          } else {
            setUserList([]);
          }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getUserByLocationID();
    }, []);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: show ? 'flex' : 'none' }} className="SelectEmployeeForm">
            <div className="content">
                <div className="Header">
                    <span>{ServiceNameLao}</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ພະນັກງານ: </label>
                                <select name="EmployeeID" onChange={(e) => handleSelectUserName(e)}
                                    className={`input form-control ${errors.EmployeeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກພະນັກງານ'}
                                    </option>
                                    {   userList && userList.map(item => (
                                        <option
                                            key={item.EmployeeID}
                                            value={item.Name}
                                        >
                                            {item.Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ຊົວໂມງນວດ: </label>
                                <input className={`input form-control ${errors.Hour && 'is-invalid'}`} type="text" name="Hour" onChange={(e) => handleInputHour(e)} value={pHour} required />
                                {errors.Hour && (
                                    <p className="invalid-feedback">{errors.Hour}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => setShow(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SelectEmployee;
