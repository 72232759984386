import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateRoom from "./create/createRoom";
import UpdateRoom from "./update/updateRoom";
import { AlertContext, UserContext } from "../../../App";
import axios from "axios";
import { API } from "../../../services/api";

axios.defaults.baseURL = API;

const RoomContext = createContext();

function Room() {

  const [RoomList, setRoomList] = useState([]);
  const [ShowRoom, setShowRoom] = useState([]);
  const [RoomData, setRoomData] = useState([]);
  const {userID, LocationID} = useContext(UserContext);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { name, value } = event.target;
    if(value){
      var results = [];
      results = RoomList.filter((data) => {
          return data.RoomName.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
        setShowRoom(results);
      else 
        setShowRoom(RoomList);
    } else 
      setShowRoom(RoomList);
  }

  const openUpdate = (RoomID, RoomName) => {
    var list = [];
    list.push({
      RoomID: RoomID,
      RoomName: RoomName
    });
    setRoomData(list);
    setShowUpdate(true);
  };

  async function getAllRoom() {
    let token = localStorage.getItem("token");
    await axios.get(`/getAllRoom/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      let temOrder = response?.data;
      if(temOrder.length > 0){
        setRoomList(temOrder);
        setShowRoom(temOrder);
      } else {
        setRoomList([]);
        setShowRoom([]);
      }
    }).catch((err) => {
      setRoomList([]);
      setShowRoom([]);
      console.log(err);
    });
  }
  
  useEffect(() => {
    getAllRoom();
  }, []);

  return (
    <RoomContext.Provider value={{LocationID, RoomList, setRoomList, RoomData, setRoomData, setShowRoom}}>
      <div className="formContent">
        <CreateRoom show={show} onClick={handleClose}/>
        <UpdateRoom show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ເລກຫ້ອງ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກຫ້ອງ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                ShowRoom && ShowRoom.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.RoomName}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.RoomID} type="button" onClick={() => openUpdate(x.RoomID, x.RoomName)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </RoomContext.Provider>
  );
};

export {RoomContext};
export default Room;
