import React, { useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/member/giftcardValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { GiftCardDataContext } from "../giftCard";

axios.defaults.baseURL = API;

function CreateGiftCard(props) {

    const {dataList, setDataList, userID, setShowData } = useContext(GiftCardDataContext);
    function currencyFormat(num) {
        if (num !== "") {
            if(num >= 0 || num <= 0){
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
    }
   
    const handleInputNameChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
        CalculatePrice(value);
    };

    const CalculatePrice = (value) => {
        if(value.substring(0, 4) == "GV5K" || value.substring(0, 4) == "gv5k"){
            handleChange("Price", 500000);
        } else if (value.substring(0, 4) == "GV3K" || value.substring(0, 4) == "gv3k"){
            handleChange("Price", 300000);
        } else if (value.substring(0, 4) == "GV2K" || value.substring(0, 4) == "gv2k"){
            handleChange("Price", 200000);
        } else {
            handleChange("Price", 0);
        }
    }

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.GiftCardNO.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckGiftCardNO", results[0].GiftCardNO);
        else
            handleChange("CheckGiftCardNO", "");
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            GiftCardNO: values.GiftCardNO,
            Price: values.Price,
            StatusID: 1,
            CreateByID: userID,
            CreateDate: new Date(),
        }
        await axios.post("/createGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            var obj = {
                GiftCardID: response?.data?.insertId,
                GiftCardNO: values.GiftCardNO,
                Price: values.Price,
                StatusID: 1,
                CreateByID: userID,
                CreateDate: new Date(),
            }
            getAllGiftCard();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllGiftCard() {
        axios
          .get("/getAllgiftcard")
          .then((response) => {
            let Data = response?.data;
            setDataList(Data);
            setShowData(Data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    const ClearData = () => {
        handleChange("GiftCardNO", "");
        handleChange("Price", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງຂໍ້ມູນບັດ GiftCard</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>GiftCardNO:</span>
                                <input className={`input form-control ${errors.GiftCardNO && 'is-invalid'}`} type="text" name="GiftCardNO" onChange={(e) => handleInputNameChange(e)} value={values.GiftCardNO || ''} required />
                                {errors.GiftCardNO && (
                                    <p className="invalid-feedback">{errors.GiftCardNO}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>Price:</span>
                                <input className={`input form-control ${errors.Price && 'is-invalid'}`} type="text" name="Price" value={currencyFormat(values.Price)} required  disabled />
                                {errors.Price && (
                                    <p className="invalid-feedback">{errors.Price}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateGiftCard;
