import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/service/packageValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { PackageContext } from "../package";

axios.defaults.baseURL = API;

function CreatePackage(props) {

    const {dataList, setDataList, setShowData} = useContext(PackageContext);

    const handleInputPaymentTypeChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.PackageName.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckPackageName", results[0].PackageName);
        else
            handleChange("CheckPackageName", "");
    }

    const handelInputNumber = (e) => {
        const { name, value } = e.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                handleChange(name, value.split(',').join(''));
            }  
        } else {
            handleChange(name, 0);
        }
    }

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            PackageName: values.PackageName,
            PackagePrice: values.PackagePrice,
        }
        await axios.post("/CreatePackage", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllPackage();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllPackage() {
        axios
          .get("/getAllPackage")
          .then((response) => {
            let Data = response?.data;
            setDataList(Data);
            setShowData(Data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    const ClearData = () => {
        handleChange("PackageName", "");
        handleChange("PackagePrice", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງຂໍ້ມູນ Package</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ຊື່ Package</span>
                                <input className={`input form-control ${errors.PackageName && 'is-invalid'}`} type="text" name="PackageName" onChange={(e) => handleInputPaymentTypeChange(e)} value={values.PackageName || ''} required />
                                {errors.PackageName && (
                                    <p className="invalid-feedback">{errors.PackageName}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ລາຄາ Package</span>
                                <input className={`input form-control ${errors.PackagePrice && 'is-invalid'}`} type="text" name="PackagePrice" onChange={(e) => handelInputNumber(e)} value={values.PackagePrice || ''} required />
                                {errors.PackagePrice && (
                                    <p className="invalid-feedback">{errors.PackagePrice}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreatePackage;
