import React, { useState, useEffect, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateService from "./create/createService";
import UpdateService from "./update/updateService";
import { API } from "../../../services/api";
import axios from "axios";
axios.defaults.baseURL = API;

const ServiceContext = createContext();

function Service() {

  const [ServiceList, setServiceList] = useState([]);
  const [ServiceTypeList, setServiceTypeList] = useState([]);
  const [ServiceCategoryList, setServiceCategoryList] = useState([]);
  const [showData, setShowData] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function HandleSearchServiceByLaoName (event) {
    const {value } = event.target;
    if(value){
      let token = localStorage.getItem("token");
      await axios.put(`/SearchServiceByLaoName/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
          setShowData(temOrder);
        } else {
          setShowData(ServiceList);
        }
      }).catch((err) => {
          setShowData(ServiceList);
          console.log(err);
      });
    } else {
      setShowData(ServiceList);
    }
  }

  async function HandleSearchServiceByEngName (event) {
    const {value } = event.target;
    if(value){
      let token = localStorage.getItem("token");
      await axios.put(`/SearchServiceByEngName/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
          setShowData(temOrder);
        }else {
          setShowData(ServiceList);
        }
      }).catch((err) => {
          console.log(err);
      });
    } else {
      setShowData(ServiceList);
    }
  }

  const openUpdate = (ServiceID, ServiceTypeID, ServiceType, ServiceCategoryID, ServiceCategory, ServiceNameLao, ServiceNameEng, ServicePrice, Commission) => {
    var list = [];
    list.push({
      ServiceID: ServiceID,
      ServiceTypeID: ServiceTypeID,
      ServiceType: ServiceType,
      ServiceCategoryID: ServiceCategoryID,
      ServiceCategory: ServiceCategory,
      ServiceNameLao: ServiceNameLao,
      ServiceNameEng: ServiceNameEng,
      ServicePrice: ServicePrice,
      Commission: Commission,
    });
    console.log(list)
    setData(list);
    setShowUpdate(true);
  };

  async function getAllServiceLimitTop10() {
    axios.get("/getAllServiceLimit10").then((response) => {
      let Data = response?.data;
      setServiceList(Data);
      setShowData(Data);
    }).catch((error) => {
      console.log(error);
    });
  }

  async function getAllServiceType() {
    axios
      .get("/getAllServiceType")
      .then((response) => {
        let Data = response?.data;
        setServiceTypeList(Data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    getAllServiceLimitTop10();
    getAllServiceType();
  }, []);

  return (
    <ServiceContext.Provider value={{ServiceList, setServiceList, setShowData, ServiceTypeList, setServiceTypeList, ServiceCategoryList, setServiceCategoryList, data}}>
      <div className="formContent">
        <CreateService show={show} onClick={handleClose}/>
        <UpdateService show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ນວດ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ ຊື່ພາສາລາວ" name="LaoName" onChange={(e) => HandleSearchServiceByLaoName(e)} />
            <input placeholder="ຄົ້ນຫາ ຊື່ພາສາອັງກິດ" name="EngName" onChange={(e) => HandleSearchServiceByEngName(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ປະເພດນວດ</th>
                <th>ໝວດນວດ</th>
                <th>ຊື່ ພາສາລາວ</th>
                <th>ຊື່ ພາສາອັງກິດ</th>
                <th>ລາຄານວດ</th>
                <th>ເປີເຊັນພະນັກງານ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.ServiceType}</td>
                        <td >{x.ServiceCategory }</td>
                        <td >{x.ServiceNameLao}</td>
                        <td >{x.ServiceNameEng }</td>
                        <td >{x.ServicePrice}</td>
                        <td >{x.Commission}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.ServiceID} type="button" onClick={() => openUpdate(x.ServiceID, x.ServiceTypeID, x.ServiceType, x.ServiceCategoryID, x.ServiceCategory, x.ServiceNameLao, x.ServiceNameEng, x.ServicePrice, x.Commission)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </ServiceContext.Provider>
  );
};

export {ServiceContext};
export default Service;
