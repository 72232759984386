
function ServiceCategoryValidate(values) {

    let errors = {};

    if (!values.ServiceCategory)
        errors.ServiceCategory = 'Category is required';
    else if (values.CheckServiceCategory !== "")
        errors.ServiceCategory = 'ServiceCategory Already Exit';

    if (!values.ServiceTypeID)
        errors.ServiceTypeID = 'ServiceTypeID is required';

    return errors;
};

export default ServiceCategoryValidate;
