import React, { useState, useEffect, useRef, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/MemberTransactionReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import { API } from "../../../../services/api";
import { useReactToPrint } from 'react-to-print';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { format } from "date-fns";
axios.defaults.baseURL = API;

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      function getDate(gdate) {
        var date = new Date(gdate);
        return format(date, "dd-MM-yyyy");
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let SubTotal = 0;
      let Quantity = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      return (
          <div className="MemberTransactionReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ສະຫລຸບການໃຊ້ບັດ</span>
                      </div>
                      
                  </div>
                  <div className="PrintDate">
                      <span>ແຕ່ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="PrintDate">
                      <span>ຫາວັນທີ: {EndDate}</span> 
                  </div>
                  <div className="ReportLocation">
                      <span>ສາຂາ: {billDetails.LocationName}</span>
                  </div>
                  <div className="ReportLocation">
                      <span>ລະຫັດບັດ: {billDetails.MemberNO}</span>
                  </div>
                  <div className="ReportLocation">
                      <span>ຍອດເງິນໃນບັດ: {currencyFormat(billDetails.MemberAmount)}</span>
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="BillNo">ລາຍການນວດ</span>
                      <span className="PaymentType">ລາຄາ</span>
                      <span className="SubTotal">ຊົວໂມງ</span>
                      <span className="Discount">ລວມ</span>
                      <span className="NetTotal">ມື້ນວດ</span>
                  </div>
                  <div className="HeadLine">
                    <div className="footer-line"/>
                  </div>
                  <div className="ReportDetail">
                      {
                          productList && productList.map((x, y) => {
                            SubTotal = Number(x.SubTotal) +  Number(SubTotal);
                            Quantity =  parseFloat(x.Quantity) +  parseFloat(Quantity);
                            return (
                                <div className="Items">
                                    <div className="Detail">
                                        <div className="mds">
                                            <span className="NO">{(y + 1) }</span>
                                            <span className="BillNo">{x.ServiceNameLao}</span>
                                            <span className="PaymentType">{currencyFormat(x.Price)}</span>
                                            <span className="SubTotal">{parseFloat(x.Quantity)}</span>
                                            <span className="Discount">{currencyFormat(x.SubTotal)}</span>
                                            <span className="NetTotal">{getDate(x.CreatedDate)}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                      }
                  </div>
                   <div className="HeadLine">
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="SubTotal">{parseFloat(Quantity)}</span>
                      <span className="PaidAmount">{currencyFormat(SubTotal)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function MemberTransactionReport() {
  function getDate(gdate) {
    const today = gdate;
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return `${year}-${month}-${date} ${time}`;
  }

  function getDate2(gdate) {
    var date = new Date(gdate);
    return format(date, "dd-MM-yyyy");
  }
  
  const [StartedDate, setStartedDate] = useState(null); 
  const [EndedDate, setEndedDate] = useState(null); 
  const [LocationList, setLocationList] = useState(null); 
  const [LocationID, setLocationID] = useState(""); 
  const [LocationName, setLocationName] = useState("");
  const [MemberNO, setMemberNO] = useState("");
  const [MemberAmount, setMemberAmount] = useState("");
  const componentRef = useRef(); 

  const [showData, setShowData] = useState([]);
  const [TransactionDetail, setTransactionDetail] = useState([]);

  function currencyFormat(num) {
    if (num !== "") {
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}
  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    return selectedDate.getTime();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    if(value){
      setMemberNO(value);
    } else {
      setMemberNO("");
    }
  };

  const handleChangeStartedDate = event => {
    if (event)
        setStartedDate(event);
    else 
        setStartedDate(null);
}

const handleChangeEndedDate = event => {
    if (event)
        setEndedDate(event);
    else 
        setEndedDate(null);
}

const handleSelectLocation = (e) => {
  var data = LocationList.filter(function (item) {
      return item.LocationName == e.target.value
  })
  if (data.length > 0) {
      setLocationName(data[0].LocationName);
      setLocationID(data[0].LocationID);
  } else {
      setLocationName("");
      setLocationID("");
  }
};

  async function handleShowTransactionDetail (TransactionServiceID) {
    await axios.get(`/getTransactionServiceDetail/${TransactionServiceID}`).then((response) => {
      let Data = response?.data;
      console.log(Data);
      if(Data.length > 0){
        setTransactionDetail(Data);
      } 
    }).catch((error) => {
      console.log(error);
    });
  }

  async function SearchTransaction() {
      var StatusID = 1;
      await axios.get(`/MemberTransactionReport/${getDate(StartedDate)}/${getDate(EndedDate)}/${LocationID}/${StatusID}/${MemberNO}`).then((response) => {
        let Data = response?.data;
        handleSearchMembers(MemberNO);
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
  };

  async function handleSearchMembers(MemberNO) {
    let token = localStorage.getItem("token");
    var data = {
        MemberNO: MemberNO
    }
    await axios.post("/searchMember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let memberData = response?.data;
        if(memberData.length > 0){
            setMemberAmount(memberData[0].Amount);
        } else {
            setMemberAmount(0);
        }

    }).catch((err) => {
        console.log(err);
    });
};


  useEffect(() => {
    let userData = localStorage.getItem("locationData");
    if (userData) {
      let userRole = JSON.parse(userData);
      setLocationList(userRole);
    }
  }, []);

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານໃຊ້ບັດ</span>
          </div>
          <div className="txtSearch">
            <div className="txtMemberNO">
              <label>MemberNO: </label>
              <input placeholder="MemberNO" name="MemberNO" onChange={(e) => handleInputChange(e)} value={MemberNO || ''} />
            </div>
            <div className="StartedDate">
                <label>ແຕ່ວັນທີ: </label>
                <DatePicker className="date"
                    selected={StartedDate}
                    onChange={(e) => handleChangeStartedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="EndedDate">
                <label >ຫາວັນທີ: </label>
                <DatePicker className="date"
                    selected={EndedDate}
                    onChange={(e) => handleChangeEndedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="Location">
                <label>ສາຂາ: </label>
                <select name="LocationID" onChange={(e) => handleSelectLocation(e)}
                    className={`input form-control`}>
                    <option
                        key={""}
                        value={""}
                    >
                        {'ເລືອກສາຂາ'}
                    </option>,
                    {LocationList && LocationList.map(item => (
                        <option
                            key={item.LocationID}
                            value={item.LocationName}
                        >
                            {item.LocationName}
                        </option>
                    ))}
                </select>
            </div>
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ລາຍການນວດ</th>
                <th>ລາຄາ</th>
                <th>ຊົວໂມງ</th>
                <th>ລວມ</th>
                <th>ມື້ນວດ</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.ServiceNameLao}</td>
                        <td >{currencyFormat(x.Price)}</td>
                        <td >{parseFloat(x.Quantity)}</td>
                        <td >{currencyFormat(x.SubTotal)}</td>
                        <td >{getDate2(x.CreatedDate)}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"StartedDate": StartedDate, "EndedDate": EndedDate , "LocationName": LocationName, "MemberNO": MemberNO, "MemberAmount": MemberAmount}} 
            />
        </div>
      </div>
  );
};

export default MemberTransactionReport;
