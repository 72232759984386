import React, { useState, useEffect, useContext, useRef   } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/TopUp.css';
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/member/topupValidate';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext, AlertContext } from "../../../App";
import { useReactToPrint } from 'react-to-print';
import '../../../css/MemberBill.css';
import Alert from "../../../components/alert";


axios.defaults.baseURL = API;

class ComponentToPrint extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const billDetails = this.props.BillDetails;
        const productList = this.props.TemProductList;
        const today = Date.now();
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="MemberBillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Spa</span>
                    </div>
                    <div className="BillTitle">
                          <span>Top Up Member</span>
                      </div>
                    <div className="Cashier">
                        <span>Bill NO: {billDetails.BillNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="LocationName">
                        <span>Location: {billDetails.LocationName}</span>
                    </div>
                </div>
                <div className="footer-line"></div>
                <div className="footer-line"></div>
                <div className="BillDetail">
                    <div className="MemberNO">
                        <span>Card Number:</span>
                        <strong>{billDetails.MemberNO}</strong>
                    </div>
                    <div className="TopUpAmount">
                        <span>Previous Amount:</span>
                        <strong>{currencyFormat(billDetails.PreviousAmount)}</strong>
                    </div>
                    <div className="TopUpAmount">
                        <span>Top Up Amount:</span>
                        <strong>{currencyFormat(billDetails.TopUpAmount)}</strong>
                    </div>
                    <div className="TopUpAmount">
                        <span>NetTotal:</span>
                        <strong>{currencyFormat(billDetails.SubTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    <div className="paymentType">
                        <span>PaymentType:</span>
                        <strong>{billDetails.PaymentType}</strong>
                    </div>
                   
                    <div className="paidAmount">
                        <span>PaidAmount:</span>
                        <strong>{currencyFormat(billDetails.PaidAmount)}</strong>
                    </div>
                  
                    <div className="changeAmount">
                        <span>Change:</span>
                        <strong>{currencyFormat(billDetails.ChangeAmount)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    {/* <span>----------------------------------------------------------------------</span> */}
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>
            </div>
        );
    }
  }

function TopUp() {

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }
  const componentRef = useRef();
  const {userID, UserName, LocationName, LocationID} = useContext(UserContext);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [BillNO, setBillNO] = useState("");


  function currencyFormat(num) {
    if (num > 0) 
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    else
        return 0
  }
 
  const handleSearchMember = e => {
    const { name, value } = e.target;
    handleChange(name, value);
    if(value){
        SearchMember(value);
    } else {

    }
  }

  async function SearchMember(memberNO) {
    let token = localStorage.getItem("token");
    var data = {
        MemberNO: memberNO
    }
    await axios.post("/searchMember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let memberData = response?.data;
        if(memberData.length > 0){
            handleChange("MemberID", memberData[0].MemberID);
            handleChange("Name", memberData[0].FirstName + ' ' + memberData[0].LastName);
            handleChange("PreviousAmount", memberData[0].Amount );
        } else {
            handleChange("MemberID", "");
            handleChange("Name", "");
            handleChange("PreviousAmount", "0" );
            handleChange("TopUpAmount", "0" );
            handleChange("PaidAmount", "0" );
            handleChange("SubTotal", "0" );
        }

    }).catch((err) => {
        console.log(err);
        handleChange("MemberID", "");
        handleChange("Name", "");
        handleChange("PreviousAmount", "0" );
        handleChange("TopUpAmount", "0" );
        handleChange("PaidAmount", "0" );
        handleChange("SubTotal", "0" );
    });
};

  const handleTopUpAmount = (e) => {
    const { name, value } = e.target;
      const re = /^[0-9\b]+$/;
      if (value) {
          if (value === '' || re.test(value.split(',').join(''))) {
            if(Number(value.split(',').join('')) <= 799999){
              handleChange(name, value.split(',').join(''));
              const subTotal = Number(value.split(',').join('')) + Number(values.PreviousAmount);
              handleChange("SubTotal", subTotal);
              handleChange("DiscountAmount", "");
              handleChange("PaidAmount", value.split(',').join(''));
            } else if(Number(value.split(',').join('')) >= 800000 && Number(value.split(',').join('')) <= 1999999){
                handleChange(name, value.split(',').join(''));
                const subTotal = Number(value.split(',').join('')) + Number(values.PreviousAmount);
                handleChange("SubTotal", subTotal);
                handleChange("DiscountAmount", Number(value.split(',').join('') * 10 / 100));
                let paidAmount = value.split(',').join('') - Number(value.split(',').join('') * 10 / 100);
                handleChange("PaidAmount", paidAmount);
                handleChange("CommissionAmount", 30000);

            } else if(Number(value.split(',').join('')) >= 2000000 && Number(value.split(',').join('')) <= 4999999){
                handleChange(name, value.split(',').join(''));
                const subTotal = Number(value.split(',').join('')) + Number(values.PreviousAmount);
                handleChange("SubTotal", subTotal);
                handleChange("DiscountAmount", Number(value.split(',').join('') * 10 / 100));
                let paidAmount = value.split(',').join('') - Number(value.split(',').join('') * 10 / 100);
                handleChange("PaidAmount", paidAmount);
                handleChange("CommissionAmount", 50000);
            } else if(Number(value.split(',').join('')) >= 5000000 && Number(value.split(',').join('')) <= 9999999){
                handleChange(name, value.split(',').join(''));
                const subTotal = Number(value.split(',').join('')) + Number(values.PreviousAmount);
                handleChange("SubTotal", subTotal);
                handleChange("DiscountAmount", Number(value.split(',').join('') * 15 / 100));
                let paidAmount = value.split(',').join('') - Number(value.split(',').join('') * 15 / 100);
                handleChange("PaidAmount", paidAmount);
                handleChange("CommissionAmount", 80000);
            } else if(Number(value.split(',').join('')) >= 10000000 ){
                handleChange(name, value.split(',').join(''));
                const subTotal = Number(value.split(',').join('')) + Number(values.PreviousAmount);
                handleChange("SubTotal", subTotal);
                handleChange("DiscountAmount", Number(value.split(',').join('') * 25 / 100));
                let paidAmount = value.split(',').join('') - Number(value.split(',').join('') * 25 / 100);
                handleChange("PaidAmount", paidAmount);
                handleChange("CommissionAmount", 120000);
            }
          }  
      } else {
          handleChange("SubTotal", 0);
          handleChange(name, 0);
          handleChange("PaidAmount", "");
      }
  }

  const handlePaidAmount = event => {
    const { name, value } = event.target;
      const re = /^[0-9\b]+$/;
      if (value) {
          if (value === '' || re.test(value.split(',').join(''))) {
              handleChange(name, value.split(',').join(''));
              const change = Number(value.split(',').join('')) - Number(values.TopUpAmount.split(',').join(''));
              handleChange("ChangeAmount", change);
          }  
      } else {
          handleChange(name, value);
          handleChange("ChangeAmount", 0);
      }
  }

  const handleSelectPaymentType = (e) => {
    var data = PaymentTypeList.filter(function (item) {
        return item.PaymentType === e.target.value
    })
    if (data.length > 0) {
        handleChange("PaymentType", data[0].PaymentType);
        handleChange("PaymentTypeID", data[0].PaymentTypeID);
    } else {
        handleChange("PaymentType", "");
        handleChange("PaymentTypeID", "");
    }
};

const handleSelectEmployee = (e) => {
    var data = EmployeeList.filter(function (item) {
        return item.Name === e.target.value
    })
    if (data.length > 0) {
        handleChange("Name", data[0].Name);
        handleChange("EmployeeID", data[0].EmployeeID);
        console.log(data[0])
    } else {
        handleChange("Name", "");
        handleChange("EmployeeID", "");
    }
};

async function saveTutorial() {
    let token = localStorage.getItem("token");
    var data = {
        MemberID: values.MemberID,
        PaymentTypeID: values.PaymentTypeID,
        TopUpAmount: values.TopUpAmount,
        PreviousAmount: values.PreviousAmount,
        SubTotal: values.SubTotal,
        DiscountAmount: values.DiscountAmount,
        PaidAmount: values.PaidAmount,
        CommissionAmount: values.CommissionAmount,
        EmployeeID: values.EmployeeID,
        CreateByID: userID,
        CreateDate: getDate(),
        LocationID: LocationID,
        StatusID: 1,
    }
    await axios.post("/topupmember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        setBillNO(response?.data?.insertId)
        handlePrint();
        ClearData();
    }).catch((err) => {
        console.log(err);
    });
    
};

const handlePrint = useReactToPrint({
    content: () => componentRef.current,
});

const ClearData = () => {
    handleChange("MemberNO", "");
    handleChange("MemberID", "");
    handleChange("MemberID", "");
    handleChange("Name", "");
    handleChange("PreviousAmount", "0" );
    handleChange("TopUpAmount", "0" );
    handleChange("PaidAmount", "0" );
    handleChange("SubTotal", "0" );
    handleChange("DiscountAmount", "0" );
    handleChange("CommissionAmount", "0" );
}

const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(saveTutorial, validate);

    async function getAllEmployee() {
        let token = localStorage.getItem("token");
        await axios.get(`/getUserByLocationID/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
            setEmployeeList(temOrder);
        } else {
            setEmployeeList([]);
        }
        }).catch((err) => {
            setEmployeeList([]);
            console.log(err);
        });
    }

  useEffect(() => {

    let paymentData = localStorage.getItem("paymentTypeData");
    if (paymentData) {
      let userRole = JSON.parse(paymentData);
      setPaymentTypeList(userRole);
    }
    getAllEmployee();
  }, []);

  return (
    <div className="TopUpFormContent">
         <Alert/> 
        <form onSubmit={handleSubmit} noValidate className="form">
            <div className="leftContent">
                <div className="leftHeader">
                    <div className="leftTitle">
                        <span>ເຕີມບັດ Member</span>
                    </div>
                </div>
                <div className="leftBody">
                    <div className="txtMemberNo">
                        <span>ເລກບັດ Member</span>
                        <input className={`input form-control ${errors.MemberNO && 'is-invalid'}`} type="text" name="MemberNO" onChange={(e) => handleSearchMember(e)}  value={values.MemberNO || ''} required />
                        {errors.MemberNO && (
                        <p className="invalid-feedback">{errors.MemberNO}</p>
                        )}
                    </div>
                    <div className="txtTopUpAmount">
                        <span>ຍອດເຕີມບັດ</span>
                        <input className={`input form-control ${errors.TopUpAmount && 'is-invalid'}`} type="text" name="TopUpAmount" onChange={(e) => handleTopUpAmount(e)} value={currencyFormat(values.TopUpAmount) || ''} required disabled={!values.Name} />
                        {errors.TopUpAmount && (
                        <p className="invalid-feedback">{errors.TopUpAmount}</p>
                        )}
                    </div>
                    <div className="txtPaidAmount">
                        <span>ຍອດຈ່າຍ</span>
                        <input className={`input form-control ${errors.PaidAmount && 'is-invalid'}`} type="text" name="PaidAmount" onChange={(e) => handlePaidAmount(e)} value={currencyFormat(values.PaidAmount) || ''} required disabled />
                        {errors.PaidAmount && (
                        <p className="invalid-feedback">{errors.PaidAmount}</p>
                        )}
                    </div>
                    <div className="cbPaymentType">
                        <label>ພະນັກງານ: </label>
                        <select name="EmployeeID" onChange={(e) => handleSelectEmployee(e)}
                            className={`input form-control ${errors.EmployeeID && 'is-invalid'}`}>
                            <option
                                key={""}
                                value={""}
                            >
                                {'ເລືອກພະນັກງານ'}
                            </option>
                            {   EmployeeList && EmployeeList.map(item => (
                                <option
                                    key={item.EmployeeID}
                                    value={item.Name}
                                >
                                    {item.Name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="cbPaymentType">
                        <label>ປະເພດຈ່າຍ: </label>
                        <select name="PaymentTypeID" onChange={(e) => handleSelectPaymentType(e)}
                            className={`input form-control ${errors.PaymentTypeID && 'is-invalid'}`}>
                            <option
                                key={""}
                                value={""}
                            >
                                {'ເລືອກປະເພດຈ່າຍ'}
                            </option>
                            {   PaymentTypeList && PaymentTypeList.map(item => (
                                <option
                                    key={item.PaymentTypeID}
                                    value={item.PaymentType}
                                >
                                    {item.PaymentType}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="rightContent">
                <div className="rightBody">
                <div className="col-md-12 order-md-2 mb-12">
                    <h4 className="d-flex justify-content-between align-items-center mb-3 text-primary">
                        <span className="text">ຂໍ້ມູນບັດ Member</span>
                    </h4>
                    <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ເລກບັດ:</h6>
                        </div>
                        <span className="text-muted">{values.MemberNO}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ຊື່ລູກຄ້າ:</h6>
                        </div>
                        <span className="text-muted">{values.Name}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ຍອດເຫຼືອ:</h6>
                        </div>
                        <span className="text-muted">{currencyFormat(values.PreviousAmount)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ຍອດຕື່ມບັດ:</h6>
                        </div>
                        <span className="text-muted">{currencyFormat(values.TopUpAmount)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ຍອດເງິນໃນບັດ:</h6>
                        </div>
                        <span className="text-muted">{currencyFormat(values.SubTotal)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">% ພະນັກງານ:</h6>
                        </div>
                        <span className="text-muted">{currencyFormat(values.CommissionAmount)}</span>
                        </li>
                    </ul>
                    <ul className="list-group mb-3">
                        <h4 className="d-flex justify-content-between align-items-center mb-3 text-danger">
                        <span className="text">ຂໍ້ມູນການຈ່າຍ</span>
                        </h4>

                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ສ່ວນຫລຸດ:</h6>
                        </div>
                        <span className="text-muted">{currencyFormat(values.DiscountAmount	)}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                        <span>ຍອດຈ່າຍ:</span>
                        <strong>{currencyFormat(values.PaidAmount)}</strong>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="line"></div>
                <div className="rightFooter">
                    <div className="btnPayment"> 
                        <button type="submit" className="btn btn-outline-primary" >ຈ່າຍເງິນ</button>
                    </div>
                </div>
            </div>
        </form>
        <div style={{display: 'none'}}>
            <ComponentToPrint ref={componentRef}
                BillDetails={{"cashier": UserName, "BillNO": BillNO,
                "LocationName": LocationName, "MemberNO": values.MemberNO,"TopUpAmount": values.TopUpAmount,
                "PaymentType": values.PaymentType, "PreviousAmount": values.PreviousAmount, "SubTotal": values.SubTotal, 
                "PaidAmount": values.PaidAmount,"ChangeAmount": values.ChangeAmount}} 
            />
        </div>
      </div>
  );
};

export default TopUp;
