import React, { useEffect, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/basicInfo/roomValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { RoomContext } from "../room";

axios.defaults.baseURL = API;

function UpdateRoom(props) {

    const {LocationID, RoomList, setRoomList, RoomData, setRoomData, setShowRoom} = useContext(RoomContext);

    const handleInputTableNumberChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = RoomList.filter((data) => {
            return data.RoomName.toLowerCase() === params.toLowerCase()
        });
        if (results.length > 0)
            handleChange("CheckRoomName", results[0].RoomName);
        else
            handleChange("CheckRoomName", "");
    }

    async function updateRoom() {
        let token = localStorage.getItem("token");
        var data = {
            RoomName: values.RoomName, 
            StatusID: 1,
        }
        await axios.put(`/updateRoom/${values.RoomID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllRoom();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllRoom() {
        let token = localStorage.getItem("token");
        await axios.get(`/getAllRoom/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setRoomList(temOrder);
            setShowRoom(temOrder);
          } else {
            setRoomList([]);
            setShowRoom([]);
          }
        }).catch((err) => {
            setRoomList([]);
            setShowRoom([]);
            console.log(err);
        });
      }

    const ClearData = () => {
        handleChange("RoomID", "");
        handleChange("RoomName", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(updateRoom, validate);

    useEffect(() => {
        RoomData.forEach(value => {
            handleChange("RoomID", value.RoomID);
            handleChange("RoomName", value.RoomName);
            handleChange("CheckRoomName", "");
        });
    }, [RoomData]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂຫ້ອງ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="form-row">
                            <div className="col form-group">
                                <span>ເລກຫ້ອງ</span>
                                <input className={`input form-control ${errors.RoomName && 'is-invalid'}`} type="text" name="RoomName" onChange={(e) => handleInputTableNumberChange(e)} value={values.RoomName || ''} required />
                                {errors.RoomName && (
                                    <p className="invalid-feedback">{errors.RoomName}</p>
                                )}
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateRoom;
