
function PackageValidate(values) {

    let errors = {};

    if (!values.PackageName)
        errors.PackageName = 'ServiceNameLao is required';
    else if (values.CheckPackageName !== "")
        errors.PackageName = 'PackageName Already Exit';

    if (!values.PackagePrice)
        errors.PackagePrice = 'PackagePrice is required';

    return errors;
};

export default PackageValidate;
