import React, { useState, useEffect, useContext, useRef   } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/SellGiftCard.css';
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/member/sellgiftcardValidate';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import { useReactToPrint } from 'react-to-print';
import '../../../css/GiftCardBill.css';

axios.defaults.baseURL = API;

class ComponentToPrint extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const billDetails = this.props.BillDetails;
        const productList = this.props.TemProductList;
        const today = Date.now();
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="MemberBillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Spa</span>
                    </div>
                    <div className="BillTitle">
                          <span>GiftCard</span>
                      </div>
                    <div className="Cashier">
                        <span>Bill NO: {billDetails.BillNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="LocationName">
                        <span>Location: {billDetails.LocationName}</span>
                    </div>
                </div>
                <div className="footer-line"></div>
                <div className="footer-line"></div>
                <div className="BillDetail">
                    <div className="MemberNO">
                        <span>Card Number:</span>
                        <strong>{billDetails.GiftCardNO}</strong>
                    </div>
                    <div className="TopUpAmount">
                        <span>Price:</span>
                        <strong>{currencyFormat(billDetails.Price)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    <div className="paymentType">
                        <span>PaymentType:</span>
                        <strong>{billDetails.PaymentType}</strong>
                    </div>
                   
                    <div className="paidAmount">
                        <span>PaidAmount:</span>
                        <strong>{currencyFormat(billDetails.PaidAmount)}</strong>
                    </div>
                  
                    <div className="changeAmount">
                        <span>Change:</span>
                        <strong>{currencyFormat(billDetails.ChangeAmount)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    {/* <span>----------------------------------------------------------------------</span> */}
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>
            </div>
        );
    }
  }

// class ComponentToPrint extends React.PureComponent {
//     render() {
//         function currencyFormat(num) {
//             if (num !== "") {
//                 return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
//             }
//         }
//         const billDetails = this.props.BillDetails;
//         const productList = this.props.TemProductList;
//         const today = Date.now();
//         const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
//         return (
//             <div className="BillContent">
//                 <div className="BillHeader">
//                     <div className="BillTitle">
//                         <span>Manee Cafe</span>
//                     </div>
//                     <div className="BillTitle">
//                           <h4>GiftCard</h4>
//                       </div>
//                     <div className="Cashier">
//                         <span>Cashier: {billDetails.cashier}</span>
//                     </div>
//                     <div className="ShowDate">
//                         <span>Date: {dateNow}</span>
//                     </div>
//                     <div className="LocationName">
//                         <span>Location: {billDetails.LocationName}</span>
//                     </div>
//                 </div>
//                 <div className="footer-line"></div>
//                 <div className="footer-line"></div>
//                 <div className="BillDetail">
//                     <div className="GiftCardNO">
//                         <span>Card Number:</span>
//                         <strong>{billDetails.GiftCardNO}</strong>
//                     </div>
//                     <div className="Price">
//                         <span>Price:</span>
//                         <strong>{currencyFormat(billDetails.Price)}</strong>
//                     </div>
//                     <div className="footer-line"></div>
//                     <div className="footer-line"></div>
//                     <div className="paymentType">
//                         <span>PaymentType:</span>
//                         <strong>{billDetails.PaymentType}</strong>
//                     </div>
                   
//                     <div className="paidAmount">
//                         <span>PaidAmount:</span>
//                         <strong>{currencyFormat(billDetails.PaidAmount)}</strong>
//                     </div>
                  
//                     <div className="changeAmount">
//                         <span>Change:</span>
//                         <strong>{currencyFormat(billDetails.ChangeAmount) || 0}</strong>
//                     </div>
//                     <div className="footer-line"></div>
//                     <div className="Thankyou">
//                         <span>Thank you!</span>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
//   }

function SellGiftCard() {
  const componentRef = useRef();
  const {userID, UserName, LocationName} = useContext(UserContext);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [PaymentTypeID, setPaymentTypeID] = useState(0);
  const [BillNO, setBillNO] = useState("");
  function currencyFormat(num) {
    if (num > 0) 
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    else
        return 0
  }
 

  const handleSearchGiftCard = e => {
    const { name, value } = e.target;
    handleChange(name, value);
    if(value){
        SearchGiftCard(value);
    } else {

    }
  }

  async function SearchGiftCard(giftcardNO) {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardNO: giftcardNO
    }
    await axios.post("/searchGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let gitftCardData = response?.data;
        if(gitftCardData.length > 0){
            handleChange("GiftCardID", gitftCardData[0].GiftCardID);
            handleChange("Price", gitftCardData[0].Price);
        } else {
            handleChange("GiftCardID", "");
            handleChange("Price", "0" );
        }

    }).catch((err) => {
        console.log(err);
        handleChange("GiftCardID", "");
        handleChange("Price", "0" );
    });
};

const handlePaidAmount = event => {
const { name, value } = event.target;
    const re = /^[0-9\b]+$/;
    if (value) {
        if (value === '' || re.test(value.split(',').join(''))) {
            if(Number(value.split(',').join('')) <= values.Price){
                handleChange(name, value.split(',').join(''));
                const change = Number(value.split(',').join('')) - Number(values.Price.split(',').join(''));
                handleChange("ChangeAmount", change);
            }else{
                handleChange(name, 0);
                handleChange("ChangeAmount", 0);
            }
        }  
    } else {
        handleChange(name, value);
        handleChange("ChangeAmount", 0);
    }
}

  const handleSelectPaymentType = (e) => {
    var data = PaymentTypeList.filter(function (item) {
        return item.PaymentType === e.target.value
    })
    if (data.length > 0) {
        setPaymentTypeID(data[0].PaymentTypeID);
        handleChange("PaymentType", data[0].PaymentType);
        handleChange("PaymentTypeID", data[0].PaymentTypeID);
    } else {
        handleChange("PaymentType", "");
        handleChange("PaymentTypeID", "");
    }
};

async function saveTutorial() {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardID: values.GiftCardID,
        PaymentTypeID: values.PaymentTypeID,
        Amount: values.Price,
        CreateByID: userID,
        CreateDate: new Date(),
        StatusID: 1,
    }
    await axios.post("/sellGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        setBillNO(response?.data?.insertId)
        UpdateGiftCard();
        handlePrint();
        ClearData();
    }).catch((err) => {
        console.log(err);
    });
};

async function UpdateGiftCard() {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardID: values.GiftCardID ,
        Price: values.Price,
        StatusID: 2,
    }
    await axios.post("/useGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    }).catch((err) => {
        console.log(err);
    });
};

const handlePrint = useReactToPrint({
    content: () => componentRef.current,
});

const ClearData = () => {
    handleChange("GiftCardNO", "");
    handleChange("GiftCardID", "");
    handleChange("Price", "0" );
    handleChange("PaidAmount", "0" );
}

const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(saveTutorial, validate);

  useEffect(() => {

    let paymentData = localStorage.getItem("paymentTypeData");
    if (paymentData) {
      let userRole = JSON.parse(paymentData);
      setPaymentTypeList(userRole);
    }

  }, []);

  return (
    <div className="SellGiftFormContent">
        <form onSubmit={handleSubmit} noValidate className="form">
            <div className="leftContent">
                <div className="leftHeader">
                    <div className="leftTitle">
                        <span>ຂາຍບັດ GiftCard</span>
                    </div>
                </div>
                <div className="leftBody">
                    <div className="txtGiftCardNO">
                        <span>ເລກບັດ GiftCard</span>
                        <input className={`input form-control ${errors.GiftCardNO && 'is-invalid'}`} type="text" name="GiftCardNO" onChange={(e) => handleSearchGiftCard(e)}  value={values.GiftCardNO || ''} required />
                        {errors.GiftCardNO && (
                        <p className="invalid-feedback">{errors.GiftCardNO}</p>
                        )}
                    </div>
                    <div className="txtPaidAmount">
                        <span>ຍອດຈ່າຍ</span>
                        <input className={`input form-control ${errors.PaidAmount && 'is-invalid'}`} type="text" name="PaidAmount" onChange={(e) => handlePaidAmount(e)} value={currencyFormat(values.PaidAmount) || ''} required disabled={!values.GiftCardID } />
                        {errors.PaidAmount && (
                        <p className="invalid-feedback">{errors.PaidAmount}</p>
                        )}
                    </div>
                    <div className="cbPaymentType">
                        <label>ປະເພດຈ່າຍ: </label>
                        <select name="PaymentTypeID" onChange={(e) => handleSelectPaymentType(e)}
                            className={`input form-control ${errors.PaymentTypeID && 'is-invalid'}`}>
                            <option
                                key={""}
                                value={""}
                            >
                                {'ເລືອກໝວດສິນຄ້າ'}
                            </option>
                            {   PaymentTypeList && PaymentTypeList.map(item => (
                                <option
                                    key={item.PaymentTypeID}
                                    value={item.PaymentType}
                                >
                                    {item.PaymentType}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="rightContent">
                <div className="rightBody">
                <div className="col-md-12 order-md-2 mb-12">
                    <h4 className="d-flex justify-content-between align-items-center mb-3 text-primary">
                        <span className="text">ຂໍ້ມູນບັດ Gift Card</span>
                    </h4>
                    <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ເລກບັດ:</h6>
                        </div>
                        <span className="text-muted">{values.GiftCardNO}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ຍອດເງິນບັດ:</h6>
                        </div>
                        <span className="text-muted">{currencyFormat(values.Price)}</span>
                        </li>
                    </ul>
                    <ul className="list-group mb-3">
                        <h4 className="d-flex justify-content-between align-items-center mb-3 text-danger">
                        <span className="text">ຂໍ້ມູນການຈ່າຍ</span>
                        </h4>
                        <li className="list-group-item d-flex justify-content-between">
                        <span>ຍອດຈ່າຍ:</span>
                        <strong>{currencyFormat(values.PaidAmount)}</strong>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                        <span>ເງິນທອນ:</span>
                        <strong className="text-danger">{currencyFormat(values.ChangeAmount)}</strong>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="line"></div>
                <div className="rightFooter">
                    <div className="btnPayment"> 
                        <button type="submit" className="btn btn-outline-primary" >ຈ່າຍເງິນ</button>
                    </div>
                </div>
            </div>
        </form>
        <div style={{display: 'none'}}>
            <ComponentToPrint ref={componentRef}
                BillDetails={{"cashier": UserName, 
                "LocationName": LocationName, "GiftCardNO": values.GiftCardNO,"Price": values.Price,"BillNO": BillNO,
                "PaymentType": values.PaymentType,"PaidAmount": values.PaidAmount,"ChangeAmount": values.ChangeAmount || 0}} 
            />
        </div>
      </div>
  );
};

export default SellGiftCard;
