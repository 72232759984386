import React, { useState, useEffect, useContext, useRef   } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/SellGiftCard.css';
import useForm from "../../../services/UseForm";
import validate from '../../../validations/admin/member/topUpGiftCard';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
import { useReactToPrint } from 'react-to-print';
import '../../../css/GiftCardBill.css';

axios.defaults.baseURL = API;

class ComponentToPrint extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const billDetails = this.props.BillDetails;
        const productList = this.props.TemProductList;
        const today = Date.now();
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="MemberBillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Spa</span>
                    </div>
                    <div className="BillTitle">
                          <span>ບິນລວມບັດ Gift Card</span>
                      </div>
                    <div className="Cashier">
                        <span>Bill NO: {billDetails.BillNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="LocationName">
                        <span>Location: {billDetails.LocationName}</span>
                    </div>
                </div>
                <div className="footer-line"></div>
                <div className="footer-line"></div>
                <div className="BillDetail">
                    <div className="MemberNO">
                        <span>Card Number:</span>
                        <strong>{billDetails.GiftCardNO}</strong>
                    </div>
                    <div className="TopUpAmount">
                        <span>Price:</span>
                        <strong>{currencyFormat(billDetails.Price)}</strong>
                    </div>

                    <div className="TopUpAmount">
                        <span>TopUpAmount:</span>
                        <strong>{currencyFormat(billDetails.SubTotal)}</strong>
                    </div>
                    <div className="TopUpAmount">
                        <span>Balance:</span>
                        <strong>{currencyFormat(billDetails.NetTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    {/* <span>----------------------------------------------------------------------</span> */}
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>
            </div>
        );
    }
  }


function TopUpGiftCare() {
  const componentRef = useRef();
  const {userID, UserName, LocationName} = useContext(UserContext);
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const [PaymentTypeID, setPaymentTypeID] = useState(0);
  const [SubTotal, setSubTotal] = useState(0);
  const [NetTotal, setNetTotal] = useState(0);
  const [TempOrder, setTempOrder] = useState([]);
  const [BillNO, setBillNO] = useState("");
  function currencyFormat(num) {
    if (num > 0) 
        return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    else
        return 0
  }
 

  const handleSearchGiftCard = e => {
    const { name, value } = e.target;
    handleChange(name, value);
    if(value){
        SearchGiftCard(value);
        handleChange("GiftCardNOForTopUP", "");
        setTempOrder([]);
        localStorage.removeItem("temOrderTopUpGiftCard");
        setSubTotal(0);
        setNetTotal(0);
    } 
  }

  const handleSearchGiftCardforTopUp = e => {
    const { name, value } = e.target;
    handleChange(name, value);
    if(value){
        SearchGiftCardForTopup(value);
    } 
  }

  async function SearchGiftCard(giftcardNO) {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardNO: giftcardNO
    }
    await axios.post("/searchGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let gitftCardData = response?.data;
        if(gitftCardData.length > 0){
            handleChange("GiftCardID", gitftCardData[0].GiftCardID);
            handleChange("Price", gitftCardData[0].Price);
        } else {
            handleChange("GiftCardID", "");
            handleChange("Price", "0" );
        }

    }).catch((err) => {
        console.log(err);
        handleChange("GiftCardID", "");
        handleChange("Price", "0" );
    });
};

async function SearchGiftCardForTopup(GiftCardNO) {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardNO: GiftCardNO
    }
    await axios.post("/searchGiftCardForPayment", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let gitftCardData = response?.data;
        if(gitftCardData.length > 0)
        {
            var c =0;
            for (var i=0; i < TempOrder.length; i++) {
                if (TempOrder[i].GiftCardID == gitftCardData[0].GiftCardID) {
                    c = 1;
                    break;
                }
            }  
            if(c == 0){
                var datalist = {
                    GiftCardID: gitftCardData[0].GiftCardID,
                    GiftCardNO: gitftCardData[0].GiftCardNO,
                    Price: gitftCardData[0].Price,
                }
                TempOrder.push(datalist);
            }
            localStorage.setItem("temOrderTopUpGiftCard", JSON.stringify(TempOrder));
            getSubtotal();
        }

    }).catch((err) => {
        console.log(err);
        handleChange("GiftCardID", "");
        handleChange("Price", "0" );
    });
}

async function addOrder(GiftCardID, GiftCardNO, Price) {
    for (var i=0; i < TempOrder.length; i++) {
        if (TempOrder[i].GiftCardID == GiftCardID) {
                TempOrder.splice(i, 1);
            break;
        }
    }  
    localStorage.setItem("temOrderTopUpGiftCard", JSON.stringify(TempOrder));
    getSubtotal();
}

const getSubtotal = () => {
    let userData = localStorage.getItem("temOrderTopUpGiftCard");
    if (userData) {
      let userRole = JSON.parse(userData);
      setTempOrder(userRole);
      if(userRole.length > 0){
            var gSubTotal = 0;
            var gNetTotal = 0;
            for (var i=0; i < userRole.length; i++) {
                gSubTotal = Number(gSubTotal) + Number(userRole[i].Price);
            }  
            if(values.Price > 0){
                gNetTotal = Number(gSubTotal) + Number(values.Price);
            } else {
                gNetTotal = gSubTotal;
            }
            setSubTotal(gSubTotal);
            setNetTotal(gNetTotal);
        } else{
            setSubTotal(0);
            setNetTotal(0);
        }
    } else{
        setTempOrder([]);
        setSubTotal(0);
        setNetTotal(0);
    }
}

async function saveTutorial() {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardID: values.GiftCardID,
        Price: values.Price,
        TopUpAmount: SubTotal,
        TotalAmount: NetTotal,
        CreateByID: userID,
        CreateDate: new Date(),
        StatusID: 1,
    }
    await axios.post("/TopUpGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        setBillNO(response?.data?.insertId);
        UpdateGiftCard(values.GiftCardID);
        CreateTransactionDetail(response?.data?.insertId);
        handlePrint();
        ClearData();
    }).catch((err) => {
        console.log(err);
    });
};

async function CreateTransactionDetail(TopUpGiftCardID) {
    let token = localStorage.getItem("token");
    if(TempOrder.length > 0) {
        for (var i=0; i < TempOrder.length; i++) {
            var list;
            list ={
                TopUpGiftCardID: TopUpGiftCardID,
                GiftCardID: TempOrder[i].GiftCardID,
                Price: TempOrder[i].Price,
            };
            await axios.post("/CreateTopUpGiftCardDetail", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                UpdateDisableGiftCard(TempOrder[i].GiftCardID, TempOrder[i].Price)
            }).catch((err) => {
                console.log(err);
            });
        } 
    }
};

async function UpdateGiftCard() {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardID: values.GiftCardID ,
        Price: NetTotal,
        StatusID: 2,
    }
    console.log(data)
    await axios.post("/useGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    }).catch((err) => {
        console.log(err);
    });
};

async function UpdateDisableGiftCard(GiftCardID, Price) {
    let token = localStorage.getItem("token");
    var data = {
        GiftCardID: GiftCardID,
        Price: Price,
        StatusID: 3,
    }
    await axios.post("/useGiftCard", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
    }).catch((err) => {
        console.log(err);
    });
};

const handlePrint = useReactToPrint({
    content: () => componentRef.current,
});

const ClearData = () => {
    handleChange("GiftCardNO", "");
    handleChange("GiftCardID", "");
    handleChange("Price", "0" );
    handleChange("PaidAmount", "0" );
    handleChange("GiftCardNOForTopUP", "");
    setTempOrder([]);
    localStorage.removeItem("temOrderTopUpGiftCard");
    setSubTotal(0);
    setNetTotal(0);
}

const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(saveTutorial, validate);

  useEffect(() => {

    let paymentData = localStorage.getItem("paymentTypeData");
    if (paymentData) {
      let userRole = JSON.parse(paymentData);
      setPaymentTypeList(userRole);
    }

  }, []);

  return (
    <div className="SellGiftFormContent">
        <form onSubmit={handleSubmit} noValidate className="form">
            <div className="leftContent">
                <div className="leftHeader">
                    <div className="leftTitle">
                        <span>ລວມຍອດບັດ Gift Card</span>
                    </div>
                </div>
                <div className="leftBody">
                    <div className="txtGiftCardNO">
                        <span>ເລກບັດ GiftCard</span>
                        <input className={`input form-control ${errors.GiftCardNO && 'is-invalid'}`} type="text" name="GiftCardNO" onChange={(e) => handleSearchGiftCard(e)}  value={values.GiftCardNO || ''} required />
                        {errors.GiftCardNO && (
                        <p className="invalid-feedback">{errors.GiftCardNO}</p>
                        )}
                    </div>

                    <div className="txtGiftCardNO">
                        <span>ເລກບັດທີ່ຈະລວມ</span>
                        <input className={`input form-control ${errors.GiftCardNOForTopUP && 'is-invalid'}`} type="text" name="GiftCardNOForTopUP" onChange={(e) => handleSearchGiftCardforTopUp(e)}  value={values.GiftCardNOForTopUP} required disabled={!values.GiftCardID } />
                        {errors.GiftCardNOForTopUP && (
                        <p className="invalid-feedback">{errors.GiftCardNOForTopUP}</p>
                        )}
                    </div>
                    <div className="Body">
                        <table className="styled-table">
                            <thead>
                            <tr key={1}>
                                <th>ລ/ດ</th>
                                <th>GiftCardNO</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                TempOrder && TempOrder.map((x, y) => {
                                    return (
                                    <tr key={y}>
                                        <td >{y+1}</td>
                                        <td >{x.GiftCardNO}</td>
                                        <td >{x.Price}</td>
                                        <td >
                                            <button className="btn btn-secondary" value={x.GiftCardID} type="button" onClick={() => addOrder(x.GiftCardID, x.GiftCardNO, x.Price)}>ລົບ</button>
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        </div>

                </div>
            </div>
            <div className="rightContent">
                <div className="rightBody">
                <div className="col-md-12 order-md-2 mb-12">
                    <h4 className="d-flex justify-content-between align-items-center mb-3 text-primary">
                        <span className="text">ຂໍ້ມູນບັດ Gift Card</span>
                    </h4>
                    <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ເລກບັດ:</h6>
                        </div>
                        <span className="text-muted">{values.GiftCardNO}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h6 className="my-0">ຍອດເງິນບັດ:</h6>
                        </div>
                        <span className="text-muted">{currencyFormat(values.Price)}</span>
                        </li>
                    </ul>
                    <ul className="list-group mb-3">
                        <h4 className="d-flex justify-content-between align-items-center mb-3 text-danger">
                        <span className="text">ຂໍ້ມູນລວມບັດ</span>
                        </h4>
                        <li className="list-group-item d-flex justify-content-between">
                        <span>ຍອດເງິນລວມບັດທີ່ຈະລວມ:</span>
                        <strong>{currencyFormat(SubTotal)}</strong>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                        <span>ຍອດລວມ:</span>
                        <strong className="text-danger">{currencyFormat(NetTotal)}</strong>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="line"></div>
                <div className="rightFooter">
                    <div className="btnPayment"> 
                        <button type="submit" className="btn btn-outline-primary" >ຈ່າຍເງິນ</button>
                    </div>
                </div>
            </div>
        </form>
        <div style={{display: 'none'}}>
            <ComponentToPrint ref={componentRef}
                BillDetails={{"cashier": UserName, 
                "LocationName": LocationName, "GiftCardNO": values.GiftCardNO,"Price": values.Price,"SubTotal": SubTotal,"NetTotal": NetTotal,"BillNO": BillNO,
                "PaymentType": values.PaymentType,"PaidAmount": values.PaidAmount,"ChangeAmount": values.ChangeAmount || 0}} 
            />
        </div>
      </div>
  );
};

export default TopUpGiftCare;
