import React, { useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/service/serviceCategoryValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { ServiceCategoryContext } from "../serviceCategory";

axios.defaults.baseURL = API;

function CreateServiceCategory(props) {

    const {dataList, setDataList, ServiceTypeList, setShowData} = useContext(ServiceCategoryContext);

    const handleInputPaymentTypeChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        check(value);
    };

    const check = (params) => {
        const results = dataList.filter((data) => {
            return data.ServiceCategory.toLowerCase() === params.toLowerCase() &&
                   data.ServiceTypeID === values.ServiceTypeID
        });
        if (results.length > 0)
            handleChange("CheckServiceCategory", results[0].ServiceCategory);
        else
            handleChange("CheckServiceCategory", "");
    }

    const handleSelectServiceType = (e) => {
        var data = ServiceTypeList.filter(function (item) {
            return item.ServiceType == e.target.value
        })
        if (data.length > 0) {
            handleChange("ServiceType", data[0].ServiceType);
            handleChange("ServiceTypeID", data[0].ServiceTypeID);
        } else {
            handleChange("ServiceType", "");
            handleChange("ServiceTypeID", "");
            handleChange("ServiceCategoryID", "");
            handleChange("ServiceCategory", "");
        }
    };

    async function saveTutorial() {
        let token = localStorage.getItem("token");
        var data = {
            ServiceTypeID: values.ServiceTypeID,
            ServiceCategory: values.ServiceCategory,
        }
        await axios.post("/CreateServiceCategory", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllServiceCategory();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllServiceCategory() {
        axios
          .get("/getAllServiceCategory")
          .then((response) => {
            let Data = response?.data;
            setDataList(Data);
            setShowData(Data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    const ClearData = () => {
        handleChange("ServiceTypeID", "");
        handleChange("ServiceType", "");
        handleChange("ServiceCategoryID", "");
        handleChange("ServiceCategory", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງໝວດນວດ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ໝວດນວດ</span>
                                <input className={`input form-control ${errors.ServiceCategory && 'is-invalid'}`} type="text" name="ServiceCategory" onChange={(e) => handleInputPaymentTypeChange(e)} value={values.ServiceCategory || ''} required disabled={!values.ServiceTypeID} />
                                {errors.ServiceCategory && (
                                    <p className="invalid-feedback">{errors.ServiceCategory}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <label>ປະເພດນວດ: </label>
                                <select name="ServiceTypeID" onChange={(e) => handleSelectServiceType(e)}
                                    className={`input form-control ${errors.ServiceTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດນວດ'}
                                    </option>
                                    {   ServiceTypeList && ServiceTypeList.map(item => (
                                        <option
                                            key={item.ServiceTypeID}
                                            value={item.ServiceType}
                                        >
                                            {item.ServiceType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateServiceCategory;
