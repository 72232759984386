import React, { useState, createContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './css/admin.css';
import { API } from "./services/api";
import axios from "axios";
import logo from './img/logo/ManeeCafeLogo.jpg';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// route v6 replace  switch to Routes


// import components
// import NavbarComponent from './components/navbar';

// import pages

import Login from './pages/Login';

// import admin page

import Employee from './pages/admin/basicInfo/employee';
import PaymentType from './pages/admin/basicInfo/paymentType';
import Currency from './pages/admin/basicInfo/currency';
import Location from './pages/admin/basicInfo/location';
import UserRole from './pages/admin/basicInfo/role';
import SizeType from './pages/admin/basicInfo/sizeType';
import Status from './pages/admin/basicInfo/status';
import TableNumber from './pages/admin/basicInfo/tableNumber';
import Supplier from './pages/admin/basicInfo/supplier';
import Room from './pages/admin/basicInfo/room';

// import service

import Service from './pages/admin/service/service';
import ServiceType from './pages/admin/service/servicetype';
import ServiceCategory from './pages/admin/service/serviceCategory';
import Package from './pages/admin/service/package';
import PackageDetail from './pages/admin/service/packageDetail';

// import product 
import ProductType from './pages/admin/product/productType';
import PromotionItemInfo from './pages/admin/product/promotionItemInfo';
import Category from './pages/admin/product/category';
import Brand from './pages/admin/product/brand';
import Product from './pages/admin/product/product';
import Menu from './pages/admin/product/menu';
import SubMenu from './pages/admin/product/subMenu';
import UploadImage from './pages/admin/product/uploadImgProduct';
import ProductReport from './pages/admin/product/ProductReport';

// import Stock
import PurchaseOrder from './pages/admin/stock/purchaseOrder';
import PendingBill from './pages/admin/stock/pendingBill';
import OrderBill from './pages/admin/stock/orderBill';
import ReceivedBill from './pages/admin/stock/receivedBill';
import InventoryReport from './pages/admin/stock/report/InventoryReport';
import StoreOrderPendingBill from './pages/admin/stock/store_order_pendingBill';
import Store_order_Order_ReciveBill from './pages/admin/stock/store_order_Order_ReciveBill';
import SaleProduct from './pages/admin/stock/SaleProduct';
import StockSaleProductReport from './pages/admin/stock/report/StockSaleProductReport';
import StockSaleProductCashReport from './pages/admin/stock/report/StockSaleProductCashReport';
import TakeOutProduct from './pages/admin/stock/TakeOutProduct';
import TakeOutProductBill from './pages/admin/stock/TakeOutProductBill';

// import Store Stock
import StorePurchaseOrder from './pages/admin/store_stock/store_purchaseOrder';
import StorePendingBill from './pages/admin/store_stock/store_pendingBill';
import StoreOrderBill from './pages/admin/store_stock/store_orderBill';
import StoreReceivedBill from './pages/admin/store_stock/store_receivedBill';
import StoreInventoryReport from './pages/admin/store_stock/report/store_inventoryReport';
import StoreTakeOutProduct from './pages/admin/store_stock/StoreTakeOutProduct';
import StoreTakeOutProductBill from './pages/admin/store_stock/StoreTakeOutProductBill';

// Import Member
import Member from './pages/admin/member/member';
import TopUpReport from './pages/admin/member/report/topupreport';
import BillReport from './pages/admin/member/report/BillReport';
import CancelTopUpBillReport from './pages/admin/member/report/CancelTopUpBillReport';
import MemberTransactionReport from './pages/admin/member/report/MemberTransactionReport';
import MemberPointReport from './pages/admin/member/report/MemberPointReport';
import CommissionReport from './pages/admin/member/report/CommissionReport';


// Import Member
import RewardCard from './pages/admin/member/rewardCard';
import TopUp from './pages/admin/member/topup';
import CancelTopUpBill from './pages/admin/member/cancelTopUpBill';

//Import GiftCard 
import GiftCard from './pages/admin/member/giftCard';
import SellGiftCard from './pages/admin/member/sellgiftcard';
import CancelSellGiftCardBill from './pages/admin/member/cancelSellGiftCardBill';
import SellGiftCardReport from './pages/admin/member/report/SellGiftCardReport';
import CancelSellGiftCardBillReport from './pages/admin/member/report/CancelSellGiftCardBillReport';
import SellGiftCardBillReport from './pages/admin/member/report/SellGiftCardBillReport';
import TopUpGiftCare from './pages/admin/member/TopUpGiftCare';
import TopUpGiftCardBillReport from './pages/admin/member/report/TopUpGiftCardBillReport';

// import POS
import POS from './pages/admin/pos/pos';
import CancelBill from './pages/admin/pos/cancelBill';
import PromoItem from './pages/admin/pos/promoItem';
import TransactionReport from './pages/admin/pos/report/TransactionReport';
import CancelBillReport from './pages/admin/pos/report/CancelBillReport';
import CashReport from './pages/admin/pos/report/CashReport';
import SaleProductReport from './pages/admin/pos/report/SaleProductReport';
import TaxReport from './pages/admin/pos/report/TaxReport';
import ExchangeReport from './pages/admin/pos/report/ExchangeReport';
import EditOrder from './pages/admin/pos/editOrder';

// import TrasactionService
import TrasactionService from './pages/admin/transaction/trasactionService';
import CancelTransctionBill from './pages/admin/transaction/cancelTransactionBill';
import TransactionServiceBillReport from './pages/admin/transaction/report/TransactionServiceBillReport';
import CancelTransactionServiceBillReport from './pages/admin/transaction/report/CancelTransactionServiceBillReport';
import TransactionServiceCashReport from './pages/admin/transaction/report/TransactionServiceCashReport';
import TransactionServiceTaxReport from './pages/admin/transaction/report/TransactionServiceTaxReport';
import ServiceTransactionReport from './pages/admin/transaction/report/ServiceTransactionReport';
import ServiceCommissionReport from './pages/admin/transaction/report/ServiceCommissionReport';


// import RegisterTable from './pages/admin/pos/registertable';

import KitchenOrder from './pages/admin/pos/KitchenOrder';

// import customer page
import Customer_home from './pages/customer/customer_home';

const UserContext = createContext();
const AlertContext = createContext();

function App() {

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [login, setLogin] = useState(false);
  const [userID, setUserID] = useState();
  const [UserName, setUserName] = useState();
  const [LocationName, setLocationName] = useState();
  const [Role, setRole] = useState();
  const [LocationID, setLocationID] = useState();
  const [SubMenuList, setSubMenuList] = useState([]);
  const [KitchenList, setKitchenList] = useState();

  useEffect(() => {
    let token = localStorage.getItem("token");
    let userData = localStorage.getItem("data");
    if (token) {
      let data = { token: `MR_ADMIN ${token}` };
      axios
        .post(`${API}/verify-token`, data)
        .then((response) => {
          if (response?.status === 200) {
            setLogin(true);
            let userRole = JSON.parse(userData);
            setUserID(userRole.EmployeeID);
            setUserName(userRole.Name);
            setLocationName(userRole.LocationName);
            setRole(userRole.Role);
            setLocationID(userRole.LocationID)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  if (!login) {
    return <Login setLogin={setLogin} setUserID={setUserID} setLocationID={setLocationID} setLocationName={setLocationName} setUserName={setUserName} setRole={setRole} />;
  } 

  const navbarMenuList = ["ຂໍ້ມູນພື້ນຖ້ານ", "ສ້າງຂໍ້ມູນນວດ", "ຂໍ້ມູນສິນຄ້າ", "ຂໍ້ມູນສາງ", "ຂໍ້ມູນສາງຮ້ານ", "Member", "Gift Card", "ຂາເຄື່ອງ", "ບໍລິການນວດ","SignOut"];
  const sideBardMenuList = [
    { location: 'ສາຂາ', UserRole: 'ສິດນຳໃຊ້', status: 'ສະຖານະ', supplier: 'ຜູ້ສະໜອງ', employee: 'ພະນັກງານ', paymentType: 'ປະເພດຈ່າຍ', sizeType: 'ປະເພດຂະໜາດ', TableNo: 'ສ້າງເລກຄິວ', Room: 'ສ້າງເລກຫ້ອງ'},
    { ServiceType: "ສ້າງປະເພດນວດ", ServiceCategory: "ສ້າງໝວດນວດ", Service:"ສ້າງຂໍ້ມູນນວດ", Package: "ສ້າງຂໍ້ມູນ Package", PackageDetail:"ສ້າງຂໍ້ມູນລາຍລະອຽກ Package"},
    { ProductType: "ປະເພດສິນຄ້າ", Category: "ໝວດສິນຄ້າ", Brand: "ຫຍີ່ຫໍ້ສິນຄ້າ", Menu: "ສ້າງເມນູຂາຍ", SubMenu: "ສ້າງເມນູຂາຍຍ່ອຍ", Product: "ສ້າງຂໍ້ມູນສິນຄ້າ", ProductReport:"ລາຍງານຂໍ້ມູນສິນຄ້າ"},
    { PurchaseOrder: 'ສ້າງໃບສັ່ງຊື້', PendingBill: 'ບິນລໍຖ້າສັ່ງຊື້', OrderBill: 'ບິນທີ່ສັ່ງຊື້', ReceivedBill: 'ບິນທີ່ຮັບເຄື່ອງເຂົ້າສາງ', InventoryReport: 'ລາຍງານຈຳນວນສິນຄ້າ', StoreOrderPendingBill: "ບິນຮ້ານເບີກເຄື່ອງ", Store_order_Order_ReciveBill:"ບິນຮ້ານທີ່ຮັບເຄື່ອງ", TakeOutProduct: "ນຳເຄື່ອງອອກສາງ", TakeOutProductBill: "ບິນນຳເຄື່ອງອອກສາງ"},
    { StorePurchaseOrder: 'ສ້າງໃບເບີກເຄື່ອງ', StorePendingBill: 'ບິນລໍຖ້າເບີກເຄື່ອງ', StoreOrderBill: 'ບິນລໍຖ້າຮັບເຄື່ອງ', StoreReceivedBill: 'ບິນທີ່ຮັບເຄື່ອງເຂົ້າຮ້ານ',StoreTakeOutProduct: "ນຳເຄື່ອງອອກສາງ", StoreInventoryReport: 'ລາຍງານຈຳນວນສິນຄ້າ', StoreTakeOutProductBill: 'ລາຍງານນຳເຄື່ອງອອກສາງຮ້ານ'},
    { Member: 'ສ້າງ Member', TopUp: 'ເຕີມບັດ Member',  CancelTopUpBill: 'ຍົກເລີກບິນເຕີມບັດ', TopUpReport: 'ລາຍງານເຕີມບັດ', BillReport: 'ລາຍງານບິນເຕີມບັດ', 
      CancelTopUpBillReport: 'ລາຍງານບິນເຕີມບັດທີ່ຍົກເລີກ',  MemberTransactionReport: 'ລາຍງານໃຊ້ບັດ', MemberPointReport: "ລາຍງານຍອດເຫຼືອບັດ", CommissionReport: "ລາຍງານເປີເຊັນຂາຍບັດ"},
    {GiftCard: 'ສ້າງບັດ Gift Card', SellGiftCard: 'ຂາຍບັດ Gift Card', TopUpGiftCare: 'ລວມຍອດເງິນບັດ Gift Card', CancelSellGiftCardBill: 'ຍົກເລີກບິນຂາຍ Gift Card', SellGiftCardReport: 'ລາຍງານຂາຍບັດ Gift Card', 
        SellGiftCardBillReport: 'ລາຍງານບິນຂາຍບັດ Gift Card', TopUpGiftCardBillReport: 'ລາຍງນາບິນລວມບັດ Gift Card', CancelSellGiftCardBillReport: 'ລາຍງານບິນຂາຍບັດ Gift Card ທີ່ຍົກເລີກ'},
    { POS: 'ຂາຍເຄື່ອງ', CancelBill: 'ຍົກເລີກບິນ', TransactionReport: 'ລາຍງານບິນຂາຍ', CancelBillReport: 'ລາຍງານບິນຍົກເລີກ', CashReport: 'ລາຍງານສະຫລຸບເງິນ', SaleProductReport: "ລາຍງານຂາຍສິນຄ້າ", TaxReport:'ລາຍງານອາກອນ'},
    { TrasactionService: 'ບໍລິການນວດ', CancelTransctionBill: 'ຍົກເລີກບິນ', TransactionServiceBillReport: 'ລາຍງານບິນນວດ', CancelTransactionServiceBillReport: 'ລາຍງານບິນນວດຍົກເລີກ', TransactionServiceCashReport: 'ລາຍງານສະຫລຸບເງິນ', ServiceTransactionReport: "ລາຍງານການນວດຕາມປະເພດ", TransactionServiceTaxReport:'ລາຍງານອາກອນ',  ServiceCommissionReport: "ລາຍງານເປີເຊັນນວດ"},
  ];

  const handleClick = (e) => {
    setSubMenuList(sideBardMenuList[e]);
    if(e === 9)
      signout();
  }

  const signout = () => {
     localStorage.removeItem('data');
     localStorage.removeItem('userData');
     localStorage.removeItem('token');
     localStorage.removeItem('productData');
     localStorage.removeItem('paymentTypeData');
     localStorage.removeItem('productTypeData');
     localStorage.removeItem('categoryData');
     localStorage.removeItem('currencyData');
     localStorage.removeItem('brandData');
     localStorage.removeItem('sizeTypeData');
     localStorage.removeItem('supplierData');
     localStorage.removeItem('locationData');
     localStorage.removeItem('statusData');
     localStorage.removeItem('ProductTypeData');
     localStorage.removeItem('purchaseOrderData');
     localStorage.removeItem('roleData');
     localStorage.removeItem('tableNumberData');
     localStorage.removeItem('memberData');
     localStorage.removeItem('discountData');
     localStorage.removeItem('productBySubMenuData');
     localStorage.removeItem('rewardcardData');
     localStorage.removeItem('subMenuData');
     localStorage.removeItem('menuData');
     localStorage.removeItem('tempServiceForPackage');
     setLogin(false);
  };

  if(Role === "Admin" || Role ==="Cashier" || Role === "StoreStock"){
    return (
      <AlertContext.Provider value={{showAlert, setShowAlert, errorMessage, setErrorMessage}}>
        <UserContext.Provider value={{ UserName, userID, LocationID, KitchenList, LocationName, login, setShowAlert, setErrorMessage, Role}}>
          <div className="admin_container">
              <div className="navbarContent">
                {
                  navbarMenuList && navbarMenuList.map((value, key) =>
                    {
                      if(Role === "Admin"){
                          return (
                          <div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                            {value}
                          </div>)
                      } else if(Role === "Cashier") {
                        if(value !== "ຂໍ້ມູນສາງ" && value !== "ຂໍ້ມູນສິນຄ້າ" && value !== "ຂໍ້ມູນພື້ນຖ້ານ")
                        {
                          return (<div key={key} className="navItem" onClick={() => handleClick(key)} tabIndex={key}>
                          {value}
                           </div>)
                        }
                      }
                    }
                  )
                }
              </div>
              <div className="sidebarContent">
                <div className="imglogo">
                  <img className="logo" src={logo} />
                </div>
                <div className="sideBarBody">
                  {
                    SubMenuList && Object.keys(SubMenuList).map((x, key) =>
                      {
                        if(Role === "Admin"){
                            return (
                              <Link key={key} className="link" to={{ pathname: "/" + x }} >
                                <div className="sideBarItem" tabIndex={key}>
                                  <div className="submenutext">
                                    {SubMenuList[x]}
                                  </div>
                                </div>
                              </Link>
                            )
                        } else {
                          if(SubMenuList[x] !== "ຍົກເລີກບິນເຕີມບັດ" && SubMenuList[x] !== "ລາຍງານບິນເຕີມບັດທີ່ຍົກເລີກ" && SubMenuList[x] !=="ຍົກເລີກບິນ" && SubMenuList[x] !== "ລາຍງານບິນຍົກເລີກ" && SubMenuList[x] !== "ລາຍງານອາກອນ")
                          {
                            return (
                              <Link key={key} className="link" to={{ pathname: "/" + x }} >
                                <div className="sideBarItem" tabIndex={key}>
                                  <div className="submenutext">
                                    {SubMenuList[x]}
                                  </div>
                                </div>
                              </Link>
                            )
                          }
                        }
                      }
                    )
                  }
                </div>
              </div>
              <div className="appContent">
                  <Routes>
                      <Route exact path='/employee' element={<Employee/>}/>
                      <Route exact path='/paymentType' element={<PaymentType/>}/>
                      <Route exact path='/Currency' element={<Currency/>}/>
                      <Route exact path='/location' element={<Location/>}/>
                      <Route exact path='/UserRole' element={<UserRole/>}/>
                      <Route exact path='/sizeType' element={<SizeType/>}/>
                      <Route exact path='/status' element={<Status/>}/>
                      <Route exact path='/TableNo' element={<TableNumber/>}/>
                      <Route exact path='/supplier' element={<Supplier/>}/>
                      <Route exact path='/Room' element={<Room/>}/>
                      
                      <Route exact path='/ServiceType' element={<ServiceType/>}/>
                      <Route exact path='/ServiceCategory' element={<ServiceCategory/>}/>
                      <Route exact path='/Service' element={<Service/>}/>
                      <Route exact path='/Package' element={<Package/>}/>
                      <Route exact path='/PackageDetail' element={<PackageDetail/>}/>

                      <Route exact path='/ProductType' element={<ProductType/>}/>
                      <Route exact path='/Category' element={<Category/>}/>
                      <Route exact path='/Brand' element={<Brand/>}/>
                      <Route exact path='/Menu' element={<Menu/>}/>
                      <Route exact path='/SubMenu' element={<SubMenu/>}/>
                      <Route exact path='/Product' element={<Product/>}/>
                      <Route exact path='/PromotionItemInfo' element={<PromotionItemInfo/>}/>
                      <Route exact path='/ProductReport' element={<ProductReport/>}/>
                      <Route exact path='/UploadImage' element={<UploadImage/>}/>

                      <Route exact path='/PurchaseOrder' element={<PurchaseOrder/>}/>
                      <Route exact path='/PendingBill' element={<PendingBill/>}/>
                      <Route exact path='/OrderBill' element={<OrderBill/>}/>
                      <Route exact path='/ReceivedBill' element={<ReceivedBill/>}/>
                      <Route exact path='/InventoryReport' element={<InventoryReport/>}/>
                      <Route exact path='/StoreOrderPendingBill' element={<StoreOrderPendingBill/>}/>
                      <Route exact path='/Store_order_Order_ReciveBill' element={<Store_order_Order_ReciveBill/>}/>
                      <Route exact path='/SaleProduct' element={<SaleProduct/>}/>
                      <Route exact path='/StockSaleProductReport' element={<StockSaleProductReport/>}/>
                      <Route exact path='/StockSaleProductCashReport' element={<StockSaleProductCashReport/>}/>
                      <Route exact path='/TakeOutProduct' element={<TakeOutProduct/>}/>
                      <Route exact path='/TakeOutProductBill' element={<TakeOutProductBill/>}/>
                      
                      <Route exact path='/StorePurchaseOrder' element={<StorePurchaseOrder/>}/>
                      <Route exact path='/StorePendingBill' element={<StorePendingBill/>}/>
                      <Route exact path='/StoreOrderBill' element={<StoreOrderBill/>}/>
                      <Route exact path='/StoreReceivedBill' element={<StoreReceivedBill/>}/>
                      <Route exact path='/StoreInventoryReport' element={<StoreInventoryReport/>}/>
                      <Route exact path='/StoreTakeOutProduct' element={<StoreTakeOutProduct/>}/>
                      <Route exact path='/StoreTakeOutProductBill' element={<StoreTakeOutProductBill/>}/>
                    
                      <Route exact path='/Member' element={<Member/>}/>
                      <Route exact path='/TopUp' element={<TopUp/>}/>
                      <Route exact path='/CancelTopUpBill' element={<CancelTopUpBill/>}/>
                      
                      <Route exact path='/TopUpReport' element={<TopUpReport/>}/>
                      <Route exact path='/CommissionReport' element={<CommissionReport/>}/>
                      <Route exact path='/BillReport' element={<BillReport/>}/>
                      <Route exact path='/CancelTopUpBillReport' element={<CancelTopUpBillReport/>}/> 
                      <Route exact path='/MemberTransactionReport' element={<MemberTransactionReport/>}/> 
                      <Route exact path='/MemberPointReport' element={<MemberPointReport/>}/> 
                      
                      <Route exact path='/RewardCard' element={<RewardCard/>}/>
                      <Route exact path='/GiftCard' element={<GiftCard/>}/>
                      <Route exact path='/SellGiftCard' element={<SellGiftCard/>}/>
                      <Route exact path='/CancelSellGiftCardBill' element={<CancelSellGiftCardBill/>}/>
                      <Route exact path='/SellGiftCardReport' element={<SellGiftCardReport/>}/>
                      <Route exact path='/CancelSellGiftCardBillReport' element={<CancelSellGiftCardBillReport/>}/>
                      <Route exact path='/SellGiftCardBillReport' element={<SellGiftCardBillReport/>}/>
                      <Route exact path='/TopUpGiftCare' element={<TopUpGiftCare/>}/>
                      <Route exact path='/TopUpGiftCardBillReport' element={<TopUpGiftCardBillReport/>}/>
                      
                      
                      <Route exact path='/POS' element={<POS/>}/>
                      <Route exact path='/EditOrder' element={<EditOrder/>}/>
                      <Route exact path='/CancelBill' element={<CancelBill/>}/>
                      <Route exact path='/PromoItem' element={<PromoItem/>}/>
                      <Route exact path='/TransactionReport' element={<TransactionReport/>}/>
                      <Route exact path='/CancelBillReport' element={<CancelBillReport/>}/>
                      <Route exact path='/CashReport' element={<CashReport/>}/>
                      <Route exact path='/SaleProductReport' element={<SaleProductReport/>}/>
                      <Route exact path='/TaxReport' element={<TaxReport/>}/>
                      <Route exact path='/ExchangeReport' element={<ExchangeReport/>}/>
                      <Route exact path='/KitchenOrder' element={<KitchenOrder/>}/>
                      
                      <Route exact path='/TrasactionService' element={<TrasactionService/>}/>
                      <Route exact path='/CancelTransctionBill' element={<CancelTransctionBill/>}/>
                      <Route exact path='/TransactionServiceBillReport' element={<TransactionServiceBillReport/>}/>
                      <Route exact path='/CancelTransactionServiceBillReport' element={<CancelTransactionServiceBillReport/>}/>
                      <Route exact path='/TransactionServiceCashReport' element={<TransactionServiceCashReport/>}/>
                      <Route exact path='/TransactionServiceTaxReport' element={<TransactionServiceTaxReport/>}/>
                      <Route exact path='/ServiceTransactionReport' element={<ServiceTransactionReport/>}/>
                      <Route exact path='/ServiceCommissionReport' element={<ServiceCommissionReport/>}/>
                      
                      
                  </Routes> 
              </div>
          </div>
        </UserContext.Provider>
      </AlertContext.Provider>
    );
  } else {
    return (
      <div>
        <UserContext.Provider value={{ UserName, userID, login}}>
          <Customer_home />
        </UserContext.Provider>
      </div>
        
    );
  }
}

export {AlertContext, UserContext};
export default App;
