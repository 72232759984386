import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import CreateTableNumber from "./create/createTableNumber";
import UpdateTableNumber from "./update/updateTableNumber";
import { AlertContext, UserContext } from "../../../App";
import axios from "axios";
import { API } from "../../../services/api";

axios.defaults.baseURL = API;

const tableNumberContext = createContext();

function TableNumber() {

  const [TableNumberList, setTableNumberList] = useState([]);
  const [ShowTableNumber, setShowTableNumber] = useState([]);
  const [TableNumberData, setTableNumberData] = useState([]);
  const {userID, LocationID} = useContext(UserContext);

  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCloseUpdate = () => setShowUpdate(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Search = event => {
    const { name, value } = event.target;
    if(value){
      var results = [];
      results = TableNumberList.filter((data) => {
          return data.TableNumber.toLowerCase().includes(value.toLowerCase())
      });
      if (results) 
        setShowTableNumber(results);
      else 
        setShowTableNumber(TableNumberList);
    } else 
      setShowTableNumber(TableNumberList);
  }

  const openUpdate = (TableID, TableNumber) => {
    var list = [];
    list.push({
      TableID: TableID,
      TableNumber: TableNumber
    });
    setTableNumberData(list);
    setShowUpdate(true);
  };

  async function getAllTableNumber() {
    let token = localStorage.getItem("token");
    await axios.get(`/getAlltableNumberForPos/${LocationID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      let temOrder = response?.data;
      if(temOrder.length > 0){
        setTableNumberList(temOrder);
        setShowTableNumber(temOrder);
      } else {
        setTableNumberList([]);
        setShowTableNumber([]);
      }
    }).catch((err) => {
      setTableNumberList([]);
      setShowTableNumber([]);
        console.log(err);
    });
  }

  
  useEffect(() => {
    getAllTableNumber();
  }, []);

  return (
    <tableNumberContext.Provider value={{LocationID, TableNumberList, setTableNumberList, TableNumberData, setTableNumberData, setShowTableNumber}}>
      <div className="formContent">
        <CreateTableNumber show={show} onClick={handleClose}/>
        <UpdateTableNumber show={showUpdate} onClick={handleCloseUpdate}/>
        <div className="Header">
          <div className="title">
              <span>ຂໍ້ມູນ ເລກຄິວ</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາ" onChange={(e) => Search(e)} />
            <button className="btn btn-primary" onClick={handleShow} >ເພີ່ມ</button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກຄິວ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                ShowTableNumber && ShowTableNumber.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TableNumber}</td>
                        <td >
                          <button className="btn btn-secondary" value={x.TableID} type="button" onClick={() => openUpdate(x.TableID, x.TableNumber)}>ແກ້ໄຂ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </tableNumberContext.Provider>
  );
};

export {tableNumberContext};
export default TableNumber;
