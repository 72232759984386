import React, { useEffect, useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/service/serviceValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { ServiceContext } from "../service";

axios.defaults.baseURL = API;

function UpdateService(props) {

    const {setServiceList, setShowData, data, ServiceTypeList} = useContext(ServiceContext);
    const [ServiceCategoryData, setServiceCategoryData] = useState([]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        handleChange(name, value)
        if(name === "ServiceNameLao")
            checkbyLaoName(value);
        else if(name === "ServiceNameEng")
            checkbyEngName(value);
    };

    async function checkbyLaoName (ServiceNameLao) {
        let token = localStorage.getItem("token");
        await axios.put(`/checkServiceNameLao/${ServiceNameLao}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
            handleChange("CheckServiceNameLao", temOrder[0].ServiceNameLao);
        } else {
            handleChange("CheckServiceNameLao", "");
        }
        }).catch((err) => {
            handleChange("CheckServiceNameLao", "");
            console.log(err);
        });
    }

    async function checkbyEngName (ServiceNameEng) {
        let token = localStorage.getItem("token");
        await axios.put(`/checkServiceNameEng/${ServiceNameEng}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
            handleChange("CheckServiceNameEng", temOrder[0].ServiceNameEng);
        } else {
            handleChange("CheckServiceNameEng", "");
        }
        }).catch((err) => {
            handleChange("CheckServiceNameEng", "");
            console.log(err);
        });
    }


    const handelInputNumber = (e) => {
        const { name, value } = e.target;
          const re = /^[0-9\b]+$/;
          if (value) {
              if (value === '' || re.test(value.split(',').join(''))) {
                  handleChange(name, value.split(',').join(''));
              }  
          } else {
              handleChange(name, 0);
          }
      }

    const handleSelectServiceType = (e) => {
        const { value } = e.target;
        if(value){
            getServiceCategoryByServiceTypeID(e.target.children[e.target.selectedIndex].getAttribute('data-id'));
            handleChange("ServiceType", value);
            handleChange("ServiceTypeID", e.target.children[e.target.selectedIndex].getAttribute('data-id'));
        } else {
            handleChange("ServiceType", "");
            handleChange("ServiceTypeID", "");
            handleChange("ServiceCategory", "");
            handleChange("ServiceCategoryID", "");
        }
        
    };

    async function getServiceCategoryByServiceTypeID(value) {
        let token = localStorage.getItem("token");
        await axios.get(`/getServiceCategoryByServiceTypeID/${value}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
          let temOrder = response?.data;
          if(temOrder.length > 0){
            setServiceCategoryData(temOrder);
          } else {
            setServiceCategoryData([]);
          }
        }).catch((err) => {
            setServiceCategoryData([]);
            console.log(err);
        });
    }

    const handleSelectServiceCategory = (e) => {
        const { value } = e.target;
        if(value){
            handleChange("ServiceCategory", value);
            handleChange("ServiceCategoryID", e.target.children[e.target.selectedIndex].getAttribute('data-id'));
        } else {
            handleChange("ServiceCategory", "");
            handleChange("ServiceCategoryID", "");
        }
    };

    async function update() {
        let token = localStorage.getItem("token");
        var data = {
            ServiceTypeID: values.ServiceTypeID,
            ServiceCategoryID: values.ServiceCategoryID,
            ServiceNameLao: values.ServiceNameLao,
            ServiceNameEng: values.ServiceNameEng,
            ServicePrice: values.ServicePrice,
            Commission: values.Commission,
        }
        console.log(data)
        await axios.put(`/UpdateService/${values.ServiceID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            getAllServiceLimitTop10();
            ClearData();
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    async function getAllServiceLimitTop10() {
        axios.get("/getAllServiceLimit10").then((response) => {
          let Data = response?.data;
          setServiceList(Data);
          setShowData(Data);
        }).catch((error) => {
          console.log(error);
        });
      }

    const ClearData = () => {
        handleChange("ServiceNameLao", "");
        handleChange("ServiceNameEng", "");
        handleChange("ServicePrice", "");
        handleChange("Commission", "");
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(update, validate);

    useEffect(() => {
        data.forEach(value => {
            handleChange("ServiceID", value.ServiceID);
            handleChange("ServiceTypeID", value.ServiceTypeID);
            handleChange("ServiceType", value.ServiceType);
            handleChange("ServiceCategoryID", value.ServiceCategoryID);
            handleChange("ServiceCategory", value.ServiceCategory);
            handleChange("ServiceNameLao", value.ServiceNameLao);
            handleChange("ServiceNameEng", value.ServiceNameEng);
            handleChange("ServicePrice", value.ServicePrice);
            handleChange("Commission", value.Commission);
            handleChange("CheckServiceNameLao", "");
            handleChange("CheckServiceNameEng", "");
        });

    }, [data]);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ແກ້ໄຂໝວດສິນຄ້າ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <span>ຊື່ພາສາລາວ</span>
                                <input className={`input form-control ${errors.ServiceNameLao && 'is-invalid'}`} type="text" name="ServiceNameLao" onChange={(e) => handleInputChange(e)} value={values.ServiceNameLao || ''} required />
                                {errors.ServiceNameLao && (
                                    <p className="invalid-feedback">{errors.ServiceNameLao}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ຊື່ພາສາອັງກິດ</span>
                                <input className={`input form-control ${errors.ServiceNameEng && 'is-invalid'}`} type="text" name="ServiceNameEng" onChange={(e) => handleInputChange(e)} value={values.ServiceNameEng || ''} required />
                                {errors.ServiceNameEng && (
                                    <p className="invalid-feedback">{errors.ServiceNameEng}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <span>ລາຄານວດ</span>
                                <input className={`input form-control ${errors.ServicePrice && 'is-invalid'}`} type="text" name="ServicePrice" onChange={(e) => handelInputNumber(e)} value={values.ServicePrice || ''} required />
                                {errors.ServicePrice && (
                                    <p className="invalid-feedback">{errors.ServicePrice}</p>
                                )}
                            </div>
                            <div className="col form-group">
                                <span>ເປີເຊັນພະນັກງານ</span>
                                <input className={`input form-control ${errors.Commission && 'is-invalid'}`} type="text" name="Commission" onChange={(e) => handelInputNumber(e)} value={values.Commission || ''} required />
                                {errors.Commission && (
                                    <p className="invalid-feedback">{errors.Commission}</p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group">
                                <label>ປະເພດນວດ: </label>
                                <select name="ServiceTypeID" onChange={(e) => handleSelectServiceType(e)}
                                    className={`input form-control ${errors.ServiceTypeID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດນວດ'}
                                    </option>
                                    { ServiceTypeList && ServiceTypeList.map((x, y) => {
                                        if (x.ServiceType == values.ServiceType) {
                                            return (
                                                <option 
                                                key={x.ServiceTypeID}
                                                data-id={x.ServiceTypeID}
                                                value={x.ServiceTypeID} selected>
                                                    {x.ServiceType}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.ServiceTypeID}
                                                data-id={x.ServiceTypeID}
                                                value={x.ServiceTypeID}>
                                                    {x.ServiceType}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ໜວດນວດ: </label>
                                <select name="ServiceCategoryID" onChange={(e) => handleSelectServiceCategory(e)}
                                    className={`input form-control ${errors.ServiceCategoryID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກໝວດນວດ'}
                                    </option>
                                    { ServiceCategoryData && ServiceCategoryData.map((x, y) => {
                                        if (x.ServiceCategory == values.ServiceCategory ) {
                                            return (
                                                <option 
                                                key={x.ServiceCategoryID}
                                                data-id={x.ServiceCategoryID}
                                                value={x.ServiceCategory} selected>
                                                    {x.ServiceCategory}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option key={x.ServiceCategoryID}
                                                data-id={x.ServiceCategoryID}
                                                value={x.ServiceCategory}>
                                                    {x.ServiceCategory}
                                                </option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateService;
