import React, { useState, useEffect, createContext, useContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/form.css';
import axios from "axios";
import { API } from "../../../services/api";
import { UserContext } from "../../../App";
axios.defaults.baseURL = API;

function CancelTopUpBill() {

  const {userID, LocationID, UserName, LocationName} = useContext(UserContext);
  const [showData, setShowData] = useState([]);
  const [MemberNewAmount, setMemberNewAmount] = useState(0);

  async function HandleCancelBill (TransactionID, MemberID, TopUpAmount) {
    let token = localStorage.getItem("token");
    await axios.put(`/CancelToUpBill/${TransactionID}/${userID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
      getMemberByMemberID(MemberID,TopUpAmount); 
      ClearData();
    }).catch((err) => {
        console.log(err);
    });
  }
  async function getMemberByMemberID(MemberID,TopUpAmount) {
    let token = localStorage.getItem("token");
    await axios.get(`/getMemberByMemberID/${MemberID}`, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let memberData = response?.data;
        if(memberData.length > 0){
          UpdateMember(memberData[0].Amount, MemberID, TopUpAmount, memberData[0].Point);
        } else {
          setMemberNewAmount(0);
        }
    }).catch((err) => {
        console.log(err);
    });
};

async function UpdateMember(Amount, MemberID, TopUpAmount, Point) {
  let token = localStorage.getItem("token");
  var data = {
      MemberID: MemberID,
      Amount: Number(Amount) - Number(TopUpAmount),
      Point: Point,
  }
  await axios.post("/usemember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
  }).catch((err) => {
      console.log(err);
  });
};

  const ClearData = () => {
    setShowData([]);
  }

  async function SearchTransaction(e) {
    const { value } = e.target;
    if(value){
      await axios.get(`/SearchTopUpBill/${value}`).then((response) => {
        let Data = response?.data;
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setShowData([]);
    }
  };

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ຍົກເລີກບິນເຕີມບັດ Member</span>
          </div>
          <div className="txtSearch">
            <input placeholder="ຄົ້ນຫາເລກບິນ" onChange={(e) => SearchTransaction(e)} />
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ເລກບິນ</th>
                <th>ຍອດເຕີມບັດ</th>
                <th>ຍອດຍົກມາ</th>
                <th>ຍອດເຫຼືອ</th>
                <th>ຍອດຈ່າຍ</th>
                <th>ປະເພດຈ່າຍ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.TopUpID}</td>
                        <td >{x.TopUpAmount}</td>
                        <td >{x.PreviousAmount}</td>
                        <td >{x.SubTotal}</td>
                        <td >{x.TopUpAmount}</td>
                        <td >{x.PaymentType}</td>
                        <td >
                          <button className="btn btn-danger" value={x.TransactionID} type="button" onClick={() => HandleCancelBill(x.TopUpID, x.MemberID, x.TopUpAmount)}>ຍົກເລີກບິນ</button>
                        </td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default CancelTopUpBill;
