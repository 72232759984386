
function SelectEmployeeValidate(values) {

    let errors = {};

    if (!values.EmployeeID)
        errors.EmployeeID = 'EmployeeID is required';

    if (!values.Hour)
        errors.Hour = 'Hour is required';

    return errors;
};

export default SelectEmployeeValidate;
