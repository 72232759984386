
function roomValidate(values) {

    let errors = {};
    if (!values.RoomName)
        errors.RoomName = 'RoomName is required';
    else if (values.CheckRoomName != "")
        errors.RoomName = 'RoomName Already Exit';

    return errors;
};

export default roomValidate;
