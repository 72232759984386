
function ServiceValidate(values) {

    let errors = {};

    if (!values.ServiceTypeID)
        errors.ServiceTypeID = 'ServiceTypeID is required';

    if (!values.ServiceCategoryID)
        errors.ServiceCategoryID = 'ServiceCategoryID is required';

    if (!values.ServiceNameLao)
        errors.ServiceNameLao = 'ServiceNameLao is required';
    else if (values.CheckServiceNameLao !== "")
        errors.ServiceNameLao = 'ServiceNameLao Already Exit';

    if (!values.ServiceNameEng)
        errors.ServiceNameEng = 'ServiceNameEng is required';
    else if (values.CheckServiceNameEng !== "")
        errors.ServiceNameEng = 'ServiceNameEng Already Exit';


    if (!values.ServicePrice)
        errors.ServicePrice = 'ServicePrice is required';

    if (!values.Commission)
        errors.Commission = 'Commission is required';

    return errors;
};

export default ServiceValidate;
