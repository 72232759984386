import React, { useState, useEffect, useRef, createContext  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../../css/form.css';
import '../../../../css/SaleProductReport.css';
import '../../../../css/datepicker.css';
import DatePicker from "react-datepicker";
import axios from "axios";
import { API } from "../../../../services/api";
import { useReactToPrint } from 'react-to-print';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';

axios.defaults.baseURL = API;

class PrintReport extends React.PureComponent {
  render() {
      function currencyFormat(num) {
          if (num !== "") {
              return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
      }
      const billDetails = this.props.BillDetails;
      const productList = this.props.TemProductList;
      const today = Date.now();
      let SubTotal = 0;
      let Quantity = 0;
      const StartDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.StartedDate));
      const EndDate = (new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(billDetails.EndedDate));
      let i =0;
      return (
          <div className="SaleProductReportContent">
              <div className="ReportHeader">
                    <img className="profile-img" src={logo} />
                  <div className="ReportTitle">
                      <div className="titleHeader1">
                          <span>ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</span>
                      </div>
                      <div className="titleHeader2">
                          <span>**********</span>
                      </div>
                      <div className="titleHeader3">
                          <span>ລາຍງານ ສະຫລຸບການນວດ</span>
                      </div>
                      
                  </div>
                  <div className="PrintDate">
                      <span>ແຕ່ວັນທີ # {StartDate}</span>
                  </div>
                  <div className="PrintDate">
                      <span>ຫາວັນທີ: {EndDate}</span> 
                  </div>
              </div>
              <div className="ReportBody">
                  <div className="ReportBodyHeader">
                  <span className="CountNumber">NO.</span>
                      <span className="ProductName">ຊື່ສິນຄ້າ</span>
                      <span className="Retail_Price">ລາຄາ</span>
                      <span className="Quantity">ຈຳນວນ</span>
                      <span className="SubTotal">ຍອດລວມ</span>
                  </div>
                  <div className="HeadLine">
                    <div className="footer-line"/>
                  </div>
                  <div className="ReportDetail">
                  {
                            productList && productList.map((x, y) => {
                                SubTotal = Number(x.SubTotal) +  Number(SubTotal);
                                Quantity =  parseFloat(x.Quantity) +  parseFloat(Quantity);
                              if(y < 22){
                                return (
                                  <div className="Items">
                                      <div className="Detail">
                                          <div className="mds">
                                              <span className="NO">{(y + 1) }</span>
                                              <span className="ProductName">{x.ServiceNameLao.length > 20 ? x.ServiceNameLao.substring(0, 20) + '...' : x.ServiceNameLao}</span>
                                              <span className="Retail_Price">{currencyFormat(x.Price)}</span>
                                              <span className="Quantity">{parseFloat(x.Quantity)}</span>
                                              <span className="SubTotal">{currencyFormat(x.SubTotal)}</span>
                                          </div>
                                          <div className="p-line"/>
                                      </div>
                                  </div>
                                )
                              } else if (i == 0) {
                                i++;
                                return (
                                    <div className="Items">
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="ProductName">{x.ServiceNameLao.length > 20 ? x.ServiceNameLao.substring(0, 20) + '...' : x.ServiceNameLao}</span>
                                                <span className="Retail_Price">{currencyFormat(x.Price)}</span>
                                                <span className="Quantity">{parseFloat(x.Quantity)}</span>
                                                <span className="SubTotal">{currencyFormat(x.SubTotal)}</span>
                                            </div>
                                            <div className="p-line"/>
                                        </div>
                                    </div>
                                  )
                              } else {
                                  i++;
                                  if(i== 28)
                                      i=0;
                                      return (
                                        <div className="Items">
                                            <div className="Detail">
                                                <div className="mds">
                                                    <span className="NO">{(y + 1) }</span>
                                                    <span className="ProductName">{x.ServiceNameLao.length > 20 ? x.ServiceNameLao.substring(0, 20) + '...' : x.ServiceNameLao}</span>
                                                    <span className="Retail_Price">{currencyFormat(x.Price)}</span>
                                                    <span className="Quantity">{parseFloat(x.Quantity)}</span>
                                                    <span className="SubTotal">{currencyFormat(x.SubTotal)}</span>
                                                </div>
                                                <div className="p-line"/>
                                            </div>
                                        </div>
                                    )
                              }
                              
                            })
                        }
                  </div>
                   <div className="HeadLine">
                    <div className="footer-line"></div>
                  </div>
                  <div className="ReportBodyFooter">
                      <span className="Total">ລວມທັງໝົດ:</span>
                      <span className="qty">{parseFloat(Quantity)}</span>
                      <span className="subTotal">{currencyFormat(SubTotal)}</span>
                  </div>
                  <div className="ReportSign">
                      
                      <span className="Manager">ຜູ້ກວດກາ</span>
                      <span className="StockAdmin"></span>
                      <span className="finance">ຜູ້ຄິດໄລ່</span>
                  </div>
              </div>
          </div>
      );
  }
}


function ServiceTransactionReport() {
    function getDate(gdate) {
        const today = gdate;
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
      }
  const [StartedDate, setStartedDate] = useState(null); 
  const [EndedDate, setEndedDate] = useState(null); 
  const [ServiceTypeList, setServiceTypeList] = useState(null); 
  const [ServiceType, setServiceType] = useState(""); 
  const [ServiceTypeID, setServiceTypeID] = useState("");
  const componentRef = useRef(); 

  const [showData, setShowData] = useState([]);
  const [TransactionDetail, setTransactionDetail] = useState([]);

  const filterPassedTime = time => {
    const selectedDate = new Date(time);
    return selectedDate.getTime();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const handleChangeStartedDate = event => {
    if (event)
        setStartedDate(event);
    else 
        setStartedDate(null);
}

const handleChangeEndedDate = event => {
    if (event)
        setEndedDate(event);
    else 
        setEndedDate(null);
}

const handleSelectServiceType = (e) => {
  var data = ServiceTypeList.filter(function (item) {
      return item.ServiceType == e.target.value
  })
  if (data.length > 0) {
      setServiceType(data[0].ServiceType);
      setServiceTypeID(data[0].ServiceTypeID);
  } else {
    setServiceType("");
    setServiceTypeID("");
  }
};

  async function SearchTransaction() {
      var StatusID = 1;
      await axios.get(`/ServiceTransactionReport/${getDate(StartedDate)}/${getDate(EndedDate)}/${StatusID}/${ServiceTypeID}`).then((response) => {
        let Data = response?.data;
        console.log(StartedDate, EndedDate, StatusID, ServiceTypeID)
        setShowData(Data);
      }).catch((error) => {
        console.log(error);
      });
  };

  async function getServiceType() {
    axios
    .get("/getAllServiceType")
    .then((response) => {
        let Data = response?.data;
        setServiceTypeList(Data);
    })
    .catch((error) => {
        console.log(error);
    });
  }


  useEffect(() => {
    getServiceType();
  }, []);

  return (
      <div className="formContent">
        <div className="Header">
          <div className="title">
              <span>ລາຍງານສິນຄ້າຂາຍດີ</span>
          </div>
          <div className="txtSearch">
            <div className="StartedDate">
                <label>ແຕ່ວັນທີ: </label>
                <DatePicker className="date"
                    selected={StartedDate}
                    onChange={(e) => handleChangeStartedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="EndedDate">
                <label >ຫາວັນທີ: </label>
                <DatePicker className="date"
                    selected={EndedDate}
                    onChange={(e) => handleChangeEndedDate(e)}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </div>
            <div className="Location">
                <label>ປະເພດນວດ: </label>
                <select name="ServiceType" onChange={(e) => handleSelectServiceType(e)}
                    className={`input form-control`}>
                    <option
                        key={""}
                        value={""}
                    >
                        {'ເລືອກປະເພດນວດ'}
                    </option>,
                    {ServiceTypeList && ServiceTypeList.map(item => (
                        <option
                            key={item.ServiceTypeID }
                            value={item.ServiceType}
                        >
                            {item.ServiceType}
                        </option>
                    ))}
                </select>
            </div>
            <button className="btnSearch btn btn-primary" onClick={() => SearchTransaction()}>
                ຄົ້ນຫາ
            </button>
            <button className="btnSearch btn btn-success" onClick={() => handlePrint()}>
                ພິມລາຍງານ
            </button>
          </div>
        </div>
        <div className="Body">
          <table className="styled-table">
            <thead>
              <tr key={1}>
                <th>ລ/ດ</th>
                <th>ຊື່ສິນຄ້າ</th>
                <th>ລາຄາ</th>
                <th>ຈຳນວນ</th>
                <th>ລວມ</th>
              </tr>
            </thead>
            <tbody>
              {
                showData && showData.map((x, y) => {
                    return (
                      <tr key={y}>
                        <td >{y + 1}</td>
                        <td >{x.ServiceNameLao}</td>
                        <td >{x.Price}</td>
                        <td >{x.Quantity}</td>
                        <td >{x.SubTotal}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'none'}}>
            <PrintReport ref={componentRef}
                    TemProductList={showData}
                    BillDetails={{"StartedDate": StartedDate, "EndedDate": EndedDate }} 
            />
        </div>
      </div>
  );
};

export default ServiceTransactionReport;
