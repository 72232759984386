function SellGiftCardSchema(values) {
    let errors = {};

    if (!values.GiftCardNO)
        errors.GiftCardNO = 'GiftCardNO is required';
    
    return errors;
};

export default SellGiftCardSchema;
