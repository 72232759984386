import React, { useState, useContext, useRef, useEffect, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../css/promoItemExchange.css';
import axios from "axios";
import { API } from "../../../services/api";
import Alert from "../../../components/alert";
import { useReactToPrint } from 'react-to-print';
import { AlertContext, UserContext } from "../../../App";

axios.defaults.baseURL = API;

const POSContent = createContext();

class PrintFood extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const productList = this.props.TemProductList;
        const billDetails = this.props.BillDetails;
        const today = Date.now();
        const customerName = this.props.custmer;
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="BillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Cafe</span>
                    </div>
                    <div className="TableNo">
                        <span>ເລກບັດ Member # {billDetails.MemberNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="BillTitle">
                        <span>Food</span>
                    </div>
                    <div className="BillTitle">
                        <span>{billDetails.TakeAway}</span> 
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                    <span className="CountNumber">NO.</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Qty</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"/>
                        <div className="footer-line"/>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                                if(x.ProductType == "Food" && x.PrintStatus == "N")
                                {
                                    return (
                                        <div className="Items">
                                            <div className="Detail">
                                                <div className="mds">
                                                    <span className="NO">{(y + 1) }</span>
                                                    <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                    <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                    <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                                </div>
                                            </div>
                                            <div className="Name">
                                                <span className="ProductName">{x.LaoName}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>

                </div>
            </div>
        );
    }
}

class PrintDrink extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const productList = this.props.TemProductList;
        const billDetails = this.props.BillDetails;
        const today = Date.now();
        const customerName = this.props.custmer;
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="BillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Cafe</span>
                    </div>
                    <div className="TableNo">
                        <span>ເລກບັດ Member # {billDetails.MemberNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Server: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="BillTitle">
                        <span>Drink</span>
                    </div>
                    <div className="BillTitle">
                        <span>{billDetails.TakeAway}</span> 
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                    <span className="CountNumber">NO.</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Qty</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"/>
                        <div className="footer-line"/>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                                if(x.ProductType == "Drink" || x.ProductType == "Coffee"  || x.ProductType == "Bakery")
                                {
                                    if(x.PrintStatus == "N"){
                                        return (
                                            <div className="Items">
                                                <div className="Detail">
                                                    <div className="mds">
                                                        <span className="NO">{(y + 1) }</span>
                                                        <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                        <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                        <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                                    </div>
                                                </div>
                                                <div className="Name">
                                                    <span className="ProductName">{x.LaoName}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            })
                        }
                    </div>

                </div>
            </div>
        );
    }
}

class ComponentToPrint extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        }
        const billDetails = this.props.BillDetails;
        const productList = this.props.TemProductList;
        const today = Date.now();
        var gSubTotal = 0;
        var gPoint = 0;
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="BillContent">
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Manee Cafe</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>
                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="LocationName">
                        <span>Location: {billDetails.LocationName}</span>
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                        <span className="CountNumber">NO.</span>
                        <span className="ProductName">Point</span>
                        <span className="Qty">Qty</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"></div>
                        <div className="footer-line"></div>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                              gSubTotal = Number(gSubTotal) + Number(x.SubTotal);
                              gPoint = (Number(x.Point) * Number(x.Quantity)) + Number(gPoint);
                                return (
                                    <div className="Items">
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="Price">{currencyFormat(x.Point)}</span>
                                                <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                <span className="Price">{currencyFormat(x.SubTotal)}</span>
                                            </div>
                                        </div>
                                        <div className="Name">
                                            <span className="ProductName">{x.LaoName}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
  
                </div>
                <div className="BillFooter">
                    <div className="footer-line"></div>
                    <div className="subtotal">
                        <span>SubTotal:</span>
                        <strong>{currencyFormat(gSubTotal)}</strong>
                    </div>
                    <div className="subtotal">
                        <span>SubTotal Point:</span>
                        <strong>{currencyFormat(gPoint)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    {
                        billDetails.MemberNO && 
                        <div className="MemberDetails">
                            <div className="Balance">
                                <span>Card Number:</span>
                                <strong>{billDetails.MemberNO}</strong>
                            </div>
                            <div className="Balance">
                                <span>Point:</span>
                                <strong>{currencyFormat(billDetails.MemberPoint)}</strong>
                            </div>
                            <div className="NewBalance">
                                <span>Newt Point:</span>
                                <strong>{currencyFormat(billDetails.MemberNewPoint )}</strong>
                            </div>
                        </div>
                    }
                    <div className="footer-line"></div>
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>
            </div>
        );
    }
  }

function PromoItem() {

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }

const componentRefFood = useRef();
const componentRefDrink = useRef();
const componentRef = useRef();

const {userID, UserName, LocationName, LocationID} = useContext(UserContext);
const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);


const [TakeAway, setTakeAway] = useState("Dine In (ກິນຢູ່ຮ້ານ)");
const [tw, setTw] = useState(false);
const [SubTotal, setSubTotal] = useState([]);

const [PromoItemData, setPromoItemData] = useState([]);
const [PromoItemList, setPromoItemList] = useState([]);
const [MemberID, setMemberID] = useState(0);
const [MemberPoint, setMemberPoint] = useState(0);
const [MemberNewPoint, setMemberNewPoint] = useState(0);
const [MemberNO, setMemberNO] = useState("");
const [Name, setName] = useState(0);

const handleSearchMember = e => {
    const { name, value } = e.target;
    setMemberNO(value);
    setPromoItemList("");
    if(value){
        SearchMember(value);
    } else {

    }
  }

  const handleClickTakeAway = event => {
    if(tw){
        setTakeAway("Dine In (ກິນຢູ່ຮ້ານ)");
        setTw(false);
    }else {
        setTakeAway("TakeAway (ກັບບ້ານ)");
        setTw(true);
    }  
};


  function currencyFormat(num) {
    if (num !== "" && num > 0) {
        return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }
    async function SearchMember(memberNO) {
        setPromoItemData([]);
        let token = localStorage.getItem("token");
        var data = {
            MemberNO: memberNO
        }
        await axios.post("/searchMember", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let memberData = response?.data;
            if(memberData.length > 0){
                setMemberID(memberData[0].MemberID);
                setName(memberData[0].FirstName + ' ' + memberData[0].LastName);
                setMemberPoint(memberData[0].Point);
                setMemberNewPoint(memberData[0].Point);
                getAllPromoProductByMemberPoint(memberData[0].Point);
            } else {
                setMemberID("");
                setName("");
                setMemberPoint("");
            }

        }).catch((err) => {
            console.log(err);
            setMemberID("");
            setName("");
            setMemberPoint("");
        });
    };

    async function getAllPromoProductByMemberPoint (Point) {
    if(Point){
        var data={
            Point: Point,
        }
      let token = localStorage.getItem("token");
      await axios.post(`/getAllPromoProductByMemberPoint`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        let temOrder = response?.data;
        if(temOrder.length > 0){
            setPromoItemList(temOrder);
        } else {
            setPromoItemList("");
        }
      }).catch((err) => {
          console.log(err);
      });
    } else {
        setPromoItemList("");
    }
    }

    async function addOrder(ProductID, LaoName, Point, qty, ProductType, Retail_Price) {
        var c =0;
        for (var i=0; i < PromoItemData.length; i++) {
            if (PromoItemData[i].ProductID == ProductID) {
                if(Number(PromoItemData[i].Quantity) + Number(qty) > 0)
                {
                    if(qty > 0){
                        if(MemberNewPoint >= Point){
                            var nPoint = Number(MemberNewPoint) - Number(Point);
                            setMemberNewPoint(nPoint);
                            PromoItemData[i].LaoName = PromoItemData[i].LaoName;
                            PromoItemData[i].Quantity = Number(PromoItemData[i].Quantity) + Number(qty);
                            PromoItemData[i].SubTotal = Number(PromoItemData[i].Quantity) * Number(PromoItemData[i].Point);
                        } else {
                            setErrorMessage("ຄະແນນບໍ່ພຽງພໍ");
                            setShowAlert(true);
                        }
                    } else {
                        var nPoint = Number(MemberNewPoint) + Number(Point);
                        setMemberNewPoint(nPoint);
                        PromoItemData[i].LaoName = PromoItemData[i].LaoName;
                        PromoItemData[i].Quantity = Number(PromoItemData[i].Quantity) + Number(qty);
                        PromoItemData[i].SubTotal = Number(PromoItemData[i].Quantity) * Number(PromoItemData[i].Point);
                    }
                } else {
                    setMemberNewPoint(MemberPoint);
                    PromoItemData.splice(i, 1);
                }
                c = 1;
                break;
            }
        }  
        if(c == 0){
            if(MemberNewPoint >= Point){
                var nPoint = Number(MemberNewPoint) - Number(Point);
                setMemberNewPoint(nPoint);
                var data = {
                    ProductID: ProductID,
                    LaoName: LaoName,
                    Quantity: 1,
                    Point: Point,
                    SubTotal: Point,
                    ProductType: ProductType,
                    PrintStatus: "N",
                    Retail_Price: Retail_Price
                };
                PromoItemData.push(data);
            } else {
                setErrorMessage("ຄະແນນບໍ່ພຽງພໍ");
                setShowAlert(true);
            }
        }
        getSubtotal(); 
    }

    const getSubtotal = () => {
        if (PromoItemData) {
            if(PromoItemData.length > 0){
                var gSubTotal = 0;
                for (var i=0; i < PromoItemData.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(PromoItemData[i].SubTotal);
                }  
                setSubTotal(gSubTotal);
            } else{
                setSubTotal(0);
            }

        } else{
            setSubTotal(0);
        }
    }

    const handlePrintFood = () => {
        printFood();
    }

    const handlePrintDrink = () => {
        printDrink();
    }

    const printFood = useReactToPrint({
        content: () => componentRefFood.current,
    });

    const printDrink = useReactToPrint({
        content: () => componentRefDrink.current,
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

    const handlePayment = () => {
        if(PromoItemData.length > 0){
            CreateTransaction();
        } else{
             setErrorMessage("ກະລຸນາເລືອກສິນຄ້າກ່ອນ");
             setShowAlert(true);
        }
    }

    async function CreateTransaction(){
        let token = localStorage.getItem("token");
        var data = {
            MemberID: MemberID,
            PreviousPoint: MemberPoint,
            UsePoint: SubTotal,
            Point: MemberNewPoint,
            CreatedBy: userID,
            CreatedDate: getDate(),
            StatusID: 1,
            LocationID: LocationID
          };
          
        await axios.post("/CreatePromoItemExchangeTransaction", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            UpdateMember(response?.data?.insertId);
            CreateTransactionDetail(response?.data?.insertId);
            handlePrint();
            ClearData();
        }).catch((err) => {
            console.log(err);
        });
    }

    const ClearData = () => {
        const timeout = setTimeout(() => {
            setPromoItemList([]);
            setPromoItemData([]);
            setMemberNewPoint(0);
            setMemberNO("");
            setMemberPoint(0);
            setMemberID("");
            setName("");
        }, 3000);
        return () => clearTimeout(timeout);
      }

    async function UpdateMember() {
        let token = localStorage.getItem("token");
        var data = {
            MemberID: MemberID,
            Point: MemberNewPoint,
        }
        await axios.post("/UpdateMemberPoint", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    };

    async function CreateTransactionDetail (ExchangeID) {
        let token = localStorage.getItem("token");
        for (var i=0; i < PromoItemData.length; i++) {
            var data = {
                ExchangeID: ExchangeID,
                ProductID: PromoItemData[i].ProductID,
                Quantity: PromoItemData[i].Quantity,
                Point: PromoItemData[i].Point,
            };
            await axios.post("/CreatePromoItemExchangeTransactionDetail", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {

            }).catch((err) => {
                console.log(err);
            });
        }  
    }

  return (
      <div className="PromoItemFormContent">
        <Alert/> 
        <div className="PromoItemContent">
            <div className="SearchMember">
                <div className="txtMemberNO"><p>ຂໍ້ມູນບັດ Member</p></div>
                <input className={`input form-control`} type="text" name="MemberNO" onChange={(e) => handleSearchMember(e)}  value={MemberNO || ''} required />
            </div>
            <div className="PromoItemBody">
                <div className="PromoItem">
                    {
                        PromoItemList && PromoItemList.map((x, y) => {
                            return (
                                <div key={x.Tabe} className="promitem" onClick={() => addOrder(x.ProductID, x.LaoName, x.Point, 1, x.ProductType, x.Retail_Price)} >
                                    <div className="item-body">
                                        <div className="name">
                                            <p>ຊື່ສິນຄ້າ</p>
                                            <p>{x.LaoName}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        <div className="PromoRightContent">
            <div className="MemberInfo">
                <div className="txtNO"><p>ເລກບັດ: </p><p>{MemberNO}</p></div>
                <div className="txtMemberName"><p>ຊື່: </p><p>{Name}</p></div>
                <div className="txtPoint"><p>ຄະແນນ: </p><p>{MemberPoint}</p></div>
                <div className="txtNewPoint"><p>ຍອດເຫຼືອ:</p><p>{MemberNewPoint}</p></div>
            </div>
            <div className="OrderHead">
                <div className="LaoName"><p>ຊື່ສິນຄ້າ</p></div>
                <div className="Quantity">ຈຳນວນ</div>
                <div className="point">ຄະແນນ</div>
            </div>
            <div className="promoRightSidenavContent">
                {
                    PromoItemData && PromoItemData.map((x, y) => {
                        return (
                            <div key={y} className="orderContent">
                                <div className="orderFooter">
                                    <div className="price">
                                        <p>{currencyFormat(x.Retail_Price)}</p>
                                    </div>
                                    <div className="plus" onClick={() => addOrder(x.ProductID, x.LaoName, x.Point, 1)}>
                                        +
                                    </div>
                                    <div className="quantity">
                                        <p>{currencyFormat(x.Quantity)}</p>
                                    </div>
                                    <div className="minus" onClick={() => addOrder(x.ProductID, x.LaoName, x.Point, -1)}>
                                        -
                                    </div>
                                    <div className="Subtotal">
                                        <p>{currencyFormat(x.Quantity * x.Point)}</p>
                                    </div>
                                </div>
                                <div className="orderBody">
                                    <div className="detail">
                                        <div className="LaoName">
                                            <span>{y+1}. {x.LaoName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="PromoRightSidenavBottom">
                <div className="footer-subtotal">
                    <div className="footer-line"></div>
                    <div className="nettotal">
                        <span>Sutotal:</span>
                        <strong>{SubTotal}</strong>
                    </div>
                </div>
                <div className="paymentMethod">
                    <div className="btnpayment" onClick={(e) => handlePayment()}>
                        <span>Payment</span>
                    </div>
                    <div className="btnPrintFood" onClick={handlePrintFood} >
                        <span>Print Food</span>
                    </div>
                    <div className="btnPrintDrink" onClick={handlePrintDrink}>
                        <span>Print Drink</span>
                    </div>
                </div>
                <div className="form-group">
                    <label className="form-check form-check-inline takeAway">
                        <input className="form-check-input" type="radio" name="takeAway" value="TakeAway" onClick={(e) => handleClickTakeAway(e)} checked={tw} />
                        <span className="form-check-label"> TakeAway </span>
                    </label>
                </div>
            </div>
        </div>
        <div style={{display: 'none'}}>
            <PrintFood ref={componentRefFood}
                TemProductList = {PromoItemData}
                BillDetails={{"MemberNO": MemberNO,"cashier": UserName, "TakeAway": TakeAway}}
            />
                <PrintDrink ref={componentRefDrink}
                TemProductList = {PromoItemData}
                BillDetails={{"MemberNO": MemberNO,"cashier": UserName, "TakeAway": TakeAway}}
            />
            <ComponentToPrint ref={componentRef}
              TemProductList={PromoItemData}
              BillDetails={{"cashier": UserName, "LocationName": LocationName, "MemberPoint": MemberPoint,
              "MemberNO": MemberNO, "MemberNewPoint": MemberNewPoint, "SubTotal": SubTotal}} 
          />
        </div>
      </div>
  );
};

export default PromoItem;
